// TOGGLE
// -----------------------------------------------------------------------------
.toggle {
	margin: 10px 0 0;
	position: relative;
	clear: both;
	> {
		input {
			cursor: pointer;
			filter: alpha(opacity = 0);
			height: 45px;
			margin: 0;
			opacity: 0;
			position: absolute;
			width: 100%;
			z-index: 2;
		}
		label {
			@include transition (all .15s ease-out);
			background: #F4F4F4;
			border-left: 3px solid $color-primary;
			border-radius: $border-radius;
			color: $color-primary;
			display: block;
			font-size: 1.1em;
			min-height: 20px;
			padding: 12px 20px 12px 10px;
			position: relative;
			cursor: pointer;
			font-weight: 400;
			&:-moz-selection {
				background: none;
			}
			i {
				&.fa-minus {
					display: none;
				}
				&.fa-plus {
					display: inline;
				}
			}
			&:selection {
				background: none;
			}
			&:before {
				border: 6px solid transparent;
				border-left-color: inherit;
				content: '';
				margin-top: -6px;
				position: absolute;
				right: 4px;
				top: 50%;
			}
			&:hover {
				background: #f5f5f5;
			}
			+ p {
				display: block;
				overflow: hidden;
				padding-left: 30px;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 25px;
			}
			i {
				font-size: 0.7em;
				margin-right: 8px;
				position: relative;
				top: -1px;
			}
		}
		.toggle-content {
			display: none;
			> p {
				margin-bottom: 0;
				padding: 10px 0;
			}
		}
	}
	&.active {
		i {
			&.fa-minus {
				display: inline;
				color: #FFF;
			}
			&.fa-plus {
				display: none;
			}
		}
		> label {
			background: $color-primary;
			border-color: $color-primary;
			color: #FFF;
			&:before {
				border: 6px solid transparent;
				border-top-color: #FFF;
				margin-top: -3px;
				right: 10px;
			}
		}
		> p {
			white-space: normal;
		}
	}
	> p.preview-active {
		height: auto;
		white-space: normal;
	}
}

// DARK
// -----------------------------------------------------------------------------
/* dark */
html.dark {
	.toggle > label {
		background: $dark-color-3;
	}
}