// DARK SKIN
// -----------------------------------------------------------------------------
html.dark {
	.bootstrap-timepicker-widget {
		background-color: $dark-color-3;

		&:before {
			border-bottom-color: $dark-color-1;
		}

		&:after {
			border-bottom-color: $dark-color-3;
		}

		&.timepicker-orient-bottom {

			&:before {
				border-top-color: $dark-color-1;
			}

			&:after {
				border-top-color: $dark-color-3;
			}

		}

		table td {

			a {
				color: #FFF;

				&:hover {
					border-color: $dark-color-3;
				}
			}

			input {
				background-color: $dark-color-2;
				border-color: $dark-color-3;
				color: #EEE;
			}
		}
	}
}