@media only screen and (max-width: 767px) {

	#gmap {
		margin: -40px -15px 0 -15px;
	}

	html.mobile-device {
		#gmap {
			min-height: 100px;
		}
	}
}

@media only screen and (min-width: 768px) {
	#gmap {
		bottom: 0;
		height: auto !important;
		left: 0;
		position: absolute !important;
		right: 0;
		top: 0;
		min-height: 0;
	}
}

// MARKERS LIST
// -----------------------------------------------------------------------------
/* List Containing Markers */
.list-markers {
	border-bottom: 1px solid lighten( $sidebar-background, 2% );
	padding-bottom: 10px;

	li {
		position: relative;
	}

	p {
		margin: 0 0 2px 0;
		padding: 3px 55px 3px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;
	}

	.location-action {
		position: absolute;
		right: 0;
		top: 2px;

		&.location-edit {
			right: 15px;
			top: 3px;
		}

		&.location-center {
			right: 37px;
		}
	}
}

// MODAL FOR MARKERS
// -----------------------------------------------------------------------------
/* Modal Add/Edit Markers */
.marker-modal {
	.modal-dialog {
		max-width: 450px;
	}
}