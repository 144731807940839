// LABEL STATES
// -----------------------------------------------------------------------------
.label-default {
	background: $color-default;
	color: $color-default-inverse;
}

.label-sm {
	font-size: 50%;
}

@each $state in $states {
	.label-#{nth($state,1)} {
		background: #{nth($state,2)};
		color: #{nth($state,3)};
	}
}