table{
	.list_right_btn{
		float: right;
	}

	&.list_search_table{
		th{
			width: 160px;
		}
	}
}

.search_reset{
  position: absolute;
  top: 11px;
  right: 80px;
}
.search_reset_reload{
  //color:$main-color;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 80px;
  display: inline-block;
}

.list_area{
  table{
    .new{
      background-color: #01B0F0;
      color:#FFF;

      &:hover{
        background-color: #009ED7;
      }
    }
    .res{
      background-color: #00B050;

      &:hover{
        background-color: darken(#00B050,5%);;
      }
    }
    .yel{
      background-color: #FEFF00;

      &:hover{
        background-color: #E4E500;
      }
    }
    .tm{
      background-color: #b879fd;
      color:#FFF;

      &:hover{
        background-color: darken(#b879fd,5%);
      }
    }
    .canl{
      background-color: #FF0200;
      color:#FFF;

      &:hover{
        background-color: #E50100;
      }
    }
  }
  .pagination_box{
    text-align: center;
  }
}