.img-rounded {
	border-radius: 6px;
}

.img-circle {
	border-radius: 50%;
}

.img-thumbnail {
	border-radius: 8px;
	position: relative;
}