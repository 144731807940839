body.plum_content{
	background-color: #ecedf0;

	.dropdown-menu{
		min-width:auto;
	}


	.table-search{
		margin-bottom: 0;
		th{
			background-color: $thin-color;
			width: 160px;
		}


	}
	.table-apply-data{
		margin-bottom: 0;
		th{
			background-color: $thin-color;
			width: 120px;
		}
	}
	.table-apply-data2{
		margin-bottom: 0;
		th{
			background-color: $thin-color;
			//width: 180px;
		}
	}

	.pagination_box{
		text-align: center;
	}


	.chat_area{
		h4 {
		    font-weight: bold;
		    border-left: 3px solid #298470;
		    font-size: 14px;
		    padding: 2px;
		    padding-left: 6px;
		    margin: 0;
		}
	}

	.btn-vsm{
		font-size: 11px;
		padding: 3px;
		line-height: 1;
		margin-top: 6px;
	}

	.beri_form_box{
		padding: 15px;
		border: 2px solid #ccc;
		background-color: #f6f6f6;

		position: relative;

		dt{
			font-size: 12px;
			margin-bottom: 5px;
		}

		.error_message{
			display: none;
			p{
				font-size: 11px;
				color:red;
				margin-bottom: 0;
				margin-top: 5px;
			}
		}

		
	}

	.border-box{
		border: 1px solid #ccc;
		padding: 10px;

		.bold{
			font-weight: bold;
		}
	}

	.ws-pl {
		white-space: pre-line;
	}

	.no_break_in_span {
		span {
			display: inline-block;
			white-space: nowrap;
		}
	}

	.colors {
		padding: 0;
		list-style: none;
		text-align: left;
		@include clearfix;

		li {
			float: left;
			margin-right: 1em;

			& > :first-child {
				margin-right: .25em;

				&::before {
					text-shadow: 1px 1px 1px rgba(0,0,0,.125);
				}

				&:empty:not(.fa)::before {
					font-family: FontAwesome;
					content: "\f0c8";
				}
			}
		}

		&.circle li > :first-child:empty:not(.fa)::before {
			content: "\f111";
			text-shadow: 0 0 1px rgba(0,0,0,.25);
		}

		&.border li > :first-child:empty:not(.fa)::before {
			margin: 0 2px 0 1px;
			text-shadow:
				 1px  1px #ddd,
				 1px -1px #ddd,
				-1px  1px #ddd,
				-1px -1px #ddd;
		}
	}

	.link_cell {
		position: relative;

		.link_btn {
			&:hover {
				text-decoration: underline;

				.fa::before {
					border-bottom: solid 1px;
				}
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 100%;
				margin: auto;
			}
		}
	}

	.new_info{
		background-color: #FEFF00;

		&:hover {
			background-color: darken(#FEFF00, 5%);
		}
	}
	.info_list_content {
		white-space: pre-line;
	}

	[name="post_info"],
	[name="post_faq"] {
		.error_message {
			color: red;
			font-size: 12px;
			margin-bottom: 0;
			display: none;
		}
	}

	.manual_form_box {
		padding: 15px;
		border: 2px solid #ccc;
		background-color: #f6f6f6;
	}

	[name="post_manual"] {
		.form-inline {
			label {
				float: left;
			}

			#manual_btn {
				border-radius: 0;

				& + p {
					overflow: hidden;

					.placeholder {
						color: #bdbdbd;
					}
				}
			}
		}
		.error_message {
			color: red;
			font-size: 12px;
		}
	}

	.faq_table {
		.question,
		.answer {
			padding-left: 1.25em;

			&::before {
				display: inline-block;
				width: 1em;
				margin-right: .25em;
				margin-left: -1.25em;
			}
		}
		.question::before {
			content: "Q.";
			color: $pale-color;
		}
		.answer {
			white-space: pre-line;
			margin-bottom: 0;

			&::before {
				content: "A.";
				color: #be3b3b;
			}
		}

		tr.ui-sortable-helper {
			display: table;
			cursor: move;
			cursor: grabbing;
			cursor: -moz-grabbing;
			cursor: -webkit-grabbing;

			td:nth-of-type(2) {
				display: table-cell;
			}
		}

		td.ui-sortable-handle {
			cursor: pointer;
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;

			&:active {
				cursor: move;
				cursor: grabbing;
				cursor: -moz-grabbing;
				cursor: -webkit-grabbing;
			}
		}
	}

	.faq_repl_expl {
		font-size: 12px;
		color: $color-message;
		border: solid 1px $color-message;
		padding: 0 .5em;
    }

	.beri_type_select {
		width: 90%;
		margin: 0 auto;

		& > label:not(:last-of-type) {
				border-right: none;
		}
		:checked + label {
			color: #FFF;
			text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
			background-color: #298470;
			border-color: #298470 #298470 #1d5d4f;
		}
	}
	
	.result_select_group {
		.btn {
			white-space: nowrap;
		}
		.dropdown-menu {
			transform-origin: top;
			animation: dropdown-menu-expand .125s;

			li {
				cursor: pointer;
			}
		}
	}
	.result_btn_group {
		.btn-group {
			@include display(flex);

			.btn {
				white-space: nowrap;
				padding: 5px 2px;
				@include flex(1);

				&.pass_btn {
					background-color: #6198C9;
					border-color: #6198C9 #6198C9 #4177A8;
					&:hover {
						background-color: lighten(#6198C9, 4%);
						border-color: lighten(#6198C9, 4%);
					}
					&:active {
						background-color: #6198C9;
						border-color: #6198C9;
					}
				}
				&.approve_btn {
					background-color: #61C99A;
					border-color: #61C99A #61C99A #45AF7F;
					&:hover {
						background-color: lighten(#61C99A, 7%);
						border-color: lighten(#61C99A, 7%);
					}
					&:active {
						background-color: darken(#61C99A, 2%);
						border-color: darken(#61C99A, 2%);
					}
				}
				&.reject_btn {
					background-color: #DC8273;
					border-color: #DC8273 #DC8273 #C26657;
					&:hover {
						background-color: lighten(#DC8273, 4%);
						border-color: lighten(#DC8273, 4%);
					}
					&:active {
						background-color: darken(#DC8273, 2%);
						border-color: darken(#DC8273, 2%);
					}
				}

				&:nth-child(2):last-child {
					margin-left: 12px;
				}
			}

/*			@include max-screen(1280px) {
				@include flex-flow(column);

				.btn {
					@include flex(1);

					&:nth-child(2):last-child {
						margin: 15px 0 0;
					}
				}
			}*/
		}
	}

	.set_result_expl {
		font-size: 20px;
		font-weight: bold;

		&[data-result="1"] { color: #2d2dd2 }
		&[data-result="2"] { color: #d2322d }
		&[data-result="4"] { color: #1d872b }

		&::before { content: "『" }
		&::after  { content: "』" }
	}

	.calendars {
		@include display(flex);
		@include flex-wrap(wrap);
		list-style: none;
		padding: 0;
		counter-reset: month;

		.calendar {
			counter-increment: month;
			margin-bottom: 20px;

			&::before {
				content: counter(month)"月";
				display: block;
				font-size: 15px;
				font-weight: bold;
				padding-bottom: 5px;
			}

			th, td {
				text-align: center;

				&:first-child {
					color: #d2322d;
				}
				&:last-child {
					color: #2d2dd2;
				}

			}

			th:empty {
				&:nth-child(1)::before { content: "日" }
				&:nth-child(2)::before { content: "月" }
				&:nth-child(3)::before { content: "火" }
				&:nth-child(4)::before { content: "水" }
				&:nth-child(5)::before { content: "木" }
				&:nth-child(6)::before { content: "金" }
				&:nth-child(7)::before { content: "土" }
			}

			td {
				&:empty {
					background-color: whitesmoke;
					cursor: not-allowed;
				}
				&:not(:empty) {
					padding: 0;

					input {
						display: none;

						&:checked + label {
							background-color: $light-color;
							text-shadow: 0 0 1px white;
						}
					}
					label {
						cursor: pointer;
						margin: 0;
						display: block;
						padding: 8px;
						transition: background-color .125s;

						&[data-day]::before {
							content: attr(data-day);
						}

						&:hover {
							background-color: $thin-color;
						}

						&.preselected {
							background-color: lighten( $main-color, 25% );
							text-shadow: 0 0 1px white;
						}

					}
				}
			}
		}


		&.selred {
			.calendar {
				td {
					&:not(:empty) {
						input {
							&:checked + label {
								background-color: #f2c2c0;
							}
						}
						label {
							&:hover {
								background-color: #fbebea;
							}
							&.preselected {
								background-color: #edadab;
								text-shadow: 0 0 1px white;
							}
						}
					}
				}
			}
		}

	}

	.logs {
		font-size: 12px;

		th {
			font-weight: normal;
			padding-right: 1em;
		}
	}
	
	.modal {
		.btn-primary:active,
		.btn-primary.active,
		.open > .dropdown-toggle.btn-primary
		.btn-primary:active:hover,
		.btn-primary.active:hover,
		.open > .dropdown-toggle.btn-primary:hover,
		.btn-primary:active:focus,
		.btn-primary.active:focus,
		.open > .dropdown-toggle.btn-primary:focus,
		.btn-primary:active.focus,
		.btn-primary.active.focus,
		.open > .dropdown-toggle.btn-primary.focus {
			background-color: #23715f;
			border-color: #23715f;
		}
	}

	.chat_message {
		.chat_btn_group {
			@include display(flex);

			.btn {
				margin-left: 2px;

				&.chat_save_btn {
					display: none;

					&:disabled::after {
						content: '中';
					}
				}
			}
		}

		.chat_comment {
			transition: background-color .125s;
			
			&[contenteditable="true"] {
				background-color: white;
				outline: dashed 1px $main-color;
				outline-offset: 1px;
			}
		}
	}
	.batch_btns {
		@include display(flex);
		@include align-items(center);
		@include justify-content(space-between);

		ul.pagination {
			margin: 0;
		}
	}

	.middle_center {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.selnone {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.header_btns {
		@include display(flex);
		@include align-items(center);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		padding-right: inherit;
	}

	[data-nav="red_invoice"] {
		@include display(flex);
		@include flex-flow(column);

		.red_invoice_item {
			@include order(-1);
		}
	}
}

@keyframes dropdown-menu-expand {
	from { transform: scaleY(0) } to { transform: none }
}

.plum_content.modal-open{
	.datepicker {
	    z-index: 1060 !important;
	}
}

#tel_change_modal,#tel_waiting_modal{
	.form-dl{
		margin-bottom: 7px;
		display:flex;
		align-items:center;

		dt{
			width: 100px;
			padding-right: 10px;
			text-align: right;
		}
		dd{
			flex: 1;
		}
	}
	.error_message{
		background-color: #FFF;
		p{
			margin-bottom: 0;
			color:red;
			font-size: 11px;
			margin-top: 10px;
		}
	}
}


#result_btn_list,
#result_btn_list li{
	list-style:none;
	padding: 0;
	margin: 0;
}
#result_btn_list{
	li{
		display: inline-block;
	}


	#kaketsu,
	#horyu{
		padding-right: 10px;
	}

	span{
		display: inline-block;
		padding: 7px 14px;
		background-color: #f0f0f0;
		border: 1px solid #e5e5e5;
		color:#777;
		border-radius:4px;
		cursor: pointer;
	}

	#hiketsu{
		float: right;
		font-size: 11px;
		span{
			padding: 5px 10px;
			margin-top: 3px;
		}
	}

	#kaketsu.cur{
		span{
			background: #6198C9;
			border: 1px solid #4177A8;
			color:#FFF;
		}
	}
	#horyu.cur{
		span{
			background: #DCDC73;
			border: 1px solid #C4C45A;
			color:#FFF;
		}
	}
	#saishu.cur{
		span{
			background: #61C99A;
			border: 1px solid #45AF7F;
			color:#FFF;
		}
	}
	#hiketsu.cur{
		span{
			background: #DC8273;
			border: 1px solid #C26657;
			color:#FFF;
		}
	}
}

#fubi_flag_contractimg,
#fubi_flag_contract,
#fubi_flag_ident,
#must_edit_member,
#must_edit_customer{
	display: inline-block;
	padding: 5px;
	width: 80px;
	background-color: #F2F2F2;
	border: 1px solid #C5C5C5;
	text-align: center;
	border-radius: 3px;
	
	margin-left: 10px;

	cursor: pointer;

	&.cur{
		padding: 4px;
		background-color: #3C87F6;
		border: 2px solid #2167CD;
		color:#FFF;
	}
}

/* テスト環境 */
body.plum_content.staging {
	.center-sign {
		.logo {
			color: #575757;
		}
		.panel-sign {
			.panel-body {
				border-color: #575757;
			}
			.title {
				background-color: #575757;
			}
		}
		form {
			[type="submit"] {
				background-color: #575757;
				border-color: #575757 #575757 #3D3D3D;
			}
		}
	}
	.header {
		background-color: #3C3C3C;
		border: {
			bottom-color: #6D6D6D;
			top-color: #000;
		}

		.logo {
			color: #FFF;
		}
		.separator {
			@include linear-gradient(#555 60%, #5E5E5E);
		}
		.userbox {
			.name {
				color: #CCC;
			}
			&.open {
				.name {
					color: #001;
				}
			}
		}
	}
	.page-header h2 {
		border-color: #575757;
	}
	.nano > .nano-pane > .nano-slider {
		background-color: #575757;
	}
	ul.nav-main > li.nav-active > a {
		box-shadow: 2px 0 0 #575757 inset;
	}
	.client_content {
		h3 {
			color: #575757;
			border-color: #575757;
			background-color: #F0F0F0;

			span {
				border-color: #575757;
			}
		}
		h4 {
			border-color: #575757;
		}
		th, td {
			border-color: #BDBDBD;
		}
		th {
			color: #575757;
		}
		.form_table tr:nth-child(even) {
			background-color: #F0F0F0;
		}
	}
   	.table-search {
		th {
			background-color: #F0F0F0;
		}
	}
	.table-apply-data {
		th {
			background-color: #F0F0F0;
		}
	}
	.table-apply-data2 {
		th {
			background-color: #F0F0F0;
		}
	}
	.panel-primary > .panel-heading {
		background-color: #575757;
		border-bottom-color: #757575;
	}
	.form_area.confirm {
		border-color: #88a6af;
	}
	.form_area.user-confirm {
		border-color: #a18e72;
	}
}
