.form_error_message{
	color:#a94442;
	font-size: 10px;
	margin-top: 6px;
	margin-bottom: 0;
}

.year_unit{
	padding-left: 0px;
	padding-top: 5px;
}

.docment_category_radios{
	margin-top: 7px;
	display: inline-block;
	width: 19%;
	box-sizing: border-box;
}

.uploaded_file{
	display: inline-block;
	margin-top: 10px;
	padding: 5px 10px;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.address_check_box_area{
	padding-bottom: 10px;

	label{
		span{
			display: inline-block;
			padding-top: 15px;
		}
		
	}
}


.image-uploader{
	.remove-btn{
		display: none;
	}

	.drop-area,
	.image-box{
		display: none;
	}

	.drop-area{
		&.drag-on{
			
		}
	}

	.error_message{
		font-size: 11px;
		color:#FFF;
		font-weight: bold;
	}
}
.file-list{
	padding: 0;
	margin: 0;
	margin-top: 10px;
	li{
		border: 1px solid #eee;
		list-style:none;
		margin: 0;
		padding: 10px;

		position: relative;
		margin-bottom: 10px;
		background: #fff;

		.btn{
			position: absolute;
			top: 8px;
			right: 10px;

			padding: 3px 7px;

			&.text{
				font-size: 11px;
			}
		}
		&.uploading{
			.btn{
				display: none;
			}
		}
		.error_messsage{
			font-size: 11px;
			color:#D31C1C;
			font-weight: bold;
		}
		&.hasError{
			border: 1px solid #D31C1C;
		}
	}
}

.note-editor{
	.modal-body{
		padding-left:30px;
		padding-right: 30px;
	}


}
hr{
	margin-top: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #eee;
}

.iinkai{
	border:1px solid #ccc;
	padding:10px 15px;
	
	.title{
		margin-bottom: 0;
		padding-bottom: 10px;
		color: #777;
		font-size: 13px;
	}
	.col-md-6.mb-sm{
		overflow: hidden;
	}
}

.public_labels{
	.label{
		display: inline-block;
		margin-top: 1px;
		margin-bottom: 1px;
		padding-top: 0.3em;
    	padding-bottom: 0.4em;
	}
}

.enquete_form{
	margin-bottom: 20px;
	.enquete_form_add_btn{
		padding: 30px;
		border: 1px dotted #ccc;
		text-align: center;
		background-color: #FFF;
		font-size: 14px;
		color:#ccc;

		&:hover{
			background-color: #fffbf8;
			cursor: pointer;
		}
	}

	.enquete_forms{
		section{
			padding:30px;
			border: 1px solid #DCDEDD;
			margin-bottom: 20px;
			background-color: #FFF;
			box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
			position: relative;

			.enquete_remove_btn{
				position: absolute;
				top: 10px;
				right: 10px;
				padding: 2px 6px;
				border-radius: 3px;
			}

			.enquete_setting{
				display: none;
			}
		}
	}
}




.stepBar {
  position: relative;
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
  text-align: center;
  width: 100%;
  @include clearfix;
  .step {
    position: relative;
    float: left;
    display: inline-block;
    line-height: 40px;
    padding: 0 30px 0 10px;
    //padding: 0 40px 0 20px;
    background-color: #eee;
    @include box-sizing(border-box);
	
	@include sp{
    	border-radius: 4px;
    	margin-bottom: 5px;
    }

    &:before,
    &:after {
      position: absolute;
      left: -15px;
      display: block;
      content: '';
      @include not_sp{
	      background-color: #eee;
	  }
	  @include sp{
	  	  border-top-color: #eee;
	  }
      border-left: 4px solid #FFF;
      width: 20px;
      height: 20px;
      @include sp{
      	display: none;
      }
    }
    @include not_sp{
	    &:after {
	      top: 0;
	      @include transform(skew(30deg));
	    }
	    &:before {
	      bottom: 0;
	      @include transform(skew(-30deg));
	    }
	}

	@include sp{
		&:before{
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(136, 183, 213, 0);
			border-top-color: #eee;
			border-width: 5px;
			margin-left: -5px;
			display: block;
		}
	}

    &:first-child {
      @include border-left-radius(4px);
      &:before,
      &:after {
        content: none;
        @include sp{
	      	content: " ";
	    }
      }
    }
    &:last-child {
      @include border-right-radius(4px);
      @include sp{
      	&:before,
		&:after {
			content: none;
		}
      }
    }
    @include not_sp{
	    &.current {
	      color: #FFF;
	      background-color: $main-color;
	      &:before,
	      &:after {
	        background-color: $main-color;
	      }
	    }
	}
	@include sp{
		&.current {
	      color: #FFF;
	      background-color: $main-color;
	      &:before,
	      &:after {
	        border-top-color: $main-color;
	      }
	    }
	}
  }
  &.step2 {
    .step {
      width: 50%;
      @include sp{
      	width: 100%;
      }
    }
  }
  &.step3 {
    .step {
      width: 33.333%;
      @include sp{
      	width: 100%;
      }
    }
  }
  &.step4 {
    .step {
      width: 25%;
      @include sp{
      	width: 100%;
      }
    }
  }
  &.step5 {
    .step {
      width: 20%;
      @include sp{
      	width: 100%;
      }
    }
  }
  &.step6 {
    .step {
      width: 16.66666%;
      @include sp{
      	width: 100%;
      }
    }
  }
}

.client_content{
	.item_setting{
		.panel-heading + .panel-body {border-radius: 0px;}
		.panel-body{
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}

		.item_form{
			th{
				width: 100px;
			}

			.code{
				width: 160px;
			}
			.code_name{
				
			}
		}
		.staff_form{
			th{
				width: 100px;
			}
			p{
				width: 100%;
			}
		}
		.staff_form2{
			
		}
		.error_message{
			display: none;
		}

		.item_box{
			padding: 10px;
			margin-bottom: 10px;
			border: 1px solid #ccc;
		}

		.staff_box{
			padding: 10px;
			margin-bottom: 10px;
			border: 1px solid #ccc;
		}
	}

	#item_delete_modal{
		.modal-dialog{
			width: 700px;
		}
	}
	#item_edit_modal{
		.modal-dialog{
			width: 700px;
		}
	}
}

