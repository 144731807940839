.redinvoice_wrap{
	padding: 0 20px;
}

.red_invoice_title{
	font-size: 24px;
	font-weight: bold;
}
.red_invoice_form{
	margin-top: 15px;
	border: 2px solid #efefef;
	border-radius: 4px;
	background-color: #f0f0f0;
	padding: 20px;

	hr{
		border-bottom: 1px solid #bababa;
	}

	label{
		font-weight: bold;
	}
	
	.search_form_two{
		.form-control{
			padding: 2px 6px;
		}
	}

	.number_form,.number2_form,.name_form{
		margin-right: 10px;
	}

	.number_form{
		.form-control{
			width: 100px;
		}
	}
	.number2_form{
		.form-control{
			width: 40px;
		}
	}
	.name_form{
		.form-control{
			width: 120px;
		}
	}
	.birthday_form{
		.form-control{
			width: 100px;
		}
	}

	.search_form_two{
		margin-top: 10px;
	}

	.red_invoice_error_message{
		display: none;
		color: white;
		font-size: 11px;
		padding: 2px 5px;
	}
}

.redinvoice_loading{
	display: none;
	.loading_box{
		height: 100px;
	}
}
.redinvoice_detail{
	.invoice_message{
		p{
			font-size: 12px;
			color:red;
			margin-bottom: 10px;
			margin-top: 5px;
			font-weight: bold;
		}
	}
}
.redinvoice_error .invoice_message{
	border: 2px solid red;
	background-color: #fde8e8;
	p{
		font-size: 22px;
		color:red;
		margin-bottom: 10px;
		margin-top: 10px;
		font-weight: bold;
		text-align: center;
	}
}

.redinvoice_detail{

	.table-red_invoice-data{
		th{
			background-color: #f0f0f0;
		}

		.return_area{
			.form-btns{

				vertical-align: top;
			}
		}

		.return_box{
			display: inline-block;
			width: 130px;
			vertical-align: top;

			margin-top: 10px;

			display: none;

			span{
				display: inline-block;
				
				font-size: 11px;
				font-weight: bold;
			}

			input{
				
			}
		}

		&.agency_agree_table{
			display: none;
		}

		.client_mail_check{
			display: none;
			padding: 10px;
			margin-top: 10px;
			background-color: #efefef;
			border: 1px solid #ccc;

			.form-ib,select {
				display: inline-block;
			    vertical-align: text-top;
    			text-transform: lowercase;
			}
			select{
				width: 160px;
			}
			input.form-ib{
				width: 160px;
			}

			.mail_address_at{
				padding: 6px 0;
			}

			.other_domain{
				display: none;
			}

			&.show{
				display: block;
			}
		}


		.shop_check{
			display: none;
			padding: 10px;
			margin-top: 10px;
			background-color: #efefef;
			border: 1px solid #ccc;

			.agency_shop_agree_box{
				text-align: center;
			}
			.agency_shop_agree_custom{
				display: inline-block;
			}
		}

		.commentMessage{
			font-size: 16px;
			font-weight: bold;
			color:red;
		}

		.date{
			display: block;
		}

		.agency_upload_date{
			padding-top: 5px;
			font-weight: bold;
		}

		.readed_check_date{
			display: inline-block;
			margin-left: 10px;
			font-weight: bold;
			font-size: 11px;
		}
	}

	.table-red_invoice-detail{
		th{
			width: 160px;
		}
	}

	.seisan_select{
		.raduo-custom{
			display: inline-block;
		}
	}

	.result_btn_box{
		margin-top: 15px;
	}


}