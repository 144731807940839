/*===========================================
=                 Variables                 =
===========================================*/
//$main-color : #51B39E;
$main-color : #298470;
$main-color : #bd004f;
$user-color : #295e84;

$color-message : #006f90;

//$thin-color : lighten( $main-color, 45% );
$thin-color : lighten( $main-color, 60% );
$light-color : lighten( $main-color, 40% );
$pale-color : lighten( $main-color, 15% );

$user-thin-color : lighten( $user-color, 60% );
$user-light-color : lighten( $user-color, 40% );
$user-pale-color : lighten( $user-color, 15% );

$big-thin-color : #e8fffa;

$gray-main-color: #666;
$gray-thin-color: lighten( $gray-main-color, 57% );
$gray-light-color: lighten( $gray-main-color, 40% );
$gray-pale-color: lighten( $gray-main-color, 15% );