// WYSIWYG
// -----------------------------------------------------------------------------
/* Summernote */
.note-editor {
	&, &.note-frame {
		border: 1px solid #ddd;
	}

	border-radius: 4px;

	@include transition( border-color ease-in-out .15s, box-shadow ease-in-out .15s );

	&.active {
		border-color: #66afe9;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,0.6);
	}

	.note-title {
		padding-top: 0 !important;
	}

	.note-toolbar {
		background-color: #f5f5f5;
		border: {
			bottom: 1px dashed #ddd;
			left: none;
			right: none;
			top: none;
		}
		border-radius: 4px 4px 0 0;
		padding: 3px 10px 7px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		i {
			margin: 0;

			&:before {
				margin: 0;
			}
		}

		.btn-group {
			float: none !important;

			> .btn-group:last-child > .btn:first-child {
				border-bottom-left-radius: 3px;
				border-top-left-radius: 3px;
			}

			> .btn-group:first-child > .btn:last-child,
			> .btn-group:first-child > .dropdown-toggle {
				border-bottom-right-radius: 3px;
				border-top-right-radius: 3px;
			}
		}

		@media only screen and (max-width: 767px) {
			text-align: center;
		}
	}

	.note-editable {
		clear: both;
		background: #FFF;
		border: none;
		border-radius: 0 0 4px 4px;
		font-family: Arial, Helvetica, Sans-serif;
	}

	.note-statusbar {
		background: #FFF;
		border-radius: 0 0 4px 4px;

		.note-resizebar {
			border-color: #DDD;
			display: block;
		}
	}
}


// DARK
// -----------------------------------------------------------------------------
html.dark {

	.note-editor {
		border-color: $dark-color-3;
		color: #EEE;

		.note-toolbar,
		.note-statusbar {
			background: $dark-color-5;
			border-color: $dark-color-1;
		}

		.note-editable {
			background: $dark-color-3;
			border-color: $dark-color-1;
		}

		.note-statusbar .note-resizebar {
			border-color: $dark-color-1;

			.note-icon-bar {
				border-color: #444;
			}
		}

		.note-editing-area .note-editable {
			color: #EEE;
		}

		.caret {
			border-color: #FFF transparent transparent;
		}
	}

}