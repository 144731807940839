// NESTABLE
// -----------------------------------------------------------------------------
.dd {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 13px;
	line-height: 20px;
}

.dd-list {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	.dd-list {
		padding-left: 30px;
	}
}

.dd-collapsed .dd-list {
	display: none;
}

.dd-item, .dd-empty, .dd-placeholder {
	display: block;
	position: relative;
	margin: 0;
	padding: 0;
	min-height: 20px;
	font-size: 13px;
	line-height: 20px;
}

.dd-handle {
	display: block;
	height: 34px;
	margin: 5px 0;
	padding: 6px 10px;
	color: #333;
	text-decoration: none;
	font-weight: $font-weight-semibold;
	border: 1px solid #CCC;
	background: #F6F6F6;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	&:hover {
		color: $color-primary;
		background: #fff;
	}
}

.dd-item > button {
	display: block;
	position: relative;
	cursor: pointer;
	float: left;
	width: 25px;
	height: 20px;
	margin: 7px 0;
	padding: 0;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	border: 0;
	background: transparent;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	font-weight: bold;
	&:before {
		content: '+';
		display: block;
		position: absolute;
		width: 100%;
		text-align: center;
		text-indent: 0;
	}
	&[data-action="collapse"]:before {
		content: '-';
	}
}

.dd-placeholder {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	background: lighten($color-primary, 55%);
	border: 1px dashed $color-primary;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.dd-empty {
	margin: 5px 0;
	padding: 0;
	min-height: 30px;
	background: #f2fbff;
	border: 1px dashed #b6bcbf;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px dashed #bbb;
	min-height: 100px;
	background-color: #e5e5e5;
	background-image: -webkit-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), -webkit-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
	background-image: -moz-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), -moz-linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
	background-image: linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white), linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white);
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}

.dd-dragel {
	position: absolute;
	pointer-events: none;
	z-index: 9999;
	> .dd-item .dd-handle {
		margin-top: 0;
	}
	.dd-handle {
		-webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
	}
}

// DARK
// -----------------------------------------------------------------------------
/* dark */
html.dark {
	.dd-handle {
		background: $dark-color-3;
		border-color: $dark-color-2;
		color: $dark-default-text;

		&:hover {
			background: $dark-color-2;
		}
	}
}