.phpdebugbar{
	//display: none;
}

.client_login{
	
	padding-top: 60px;

	@include sp{
		padding-top: 30px;
		#pwd-container{
			margin-left: -30px;
			margin-right: -30px;
		}
	}

	.form-control {
	  position: relative;
	  font-size: 16px;
	  height: auto;
	  padding: 10px;
		@include box-sizing(border-box);

		&:focus {
		  z-index: 2;
		}
	}

	form[role=login] {
		color: #5d5d5d;
		background: #f2f2f2;
		padding: 26px;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		@include sp{
			padding: 15px;
		}
	}
	form[role=login] img {
		display: block;
		margin: 0 auto;
		margin-bottom: 35px;
	}
	form[role=login] input,
	form[role=login] button {
		font-size: 18px;
		margin: 16px 0;
		@include sp{
			margin-bottom: 0;
		}
	}
	form[role=login] > div {
		text-align: center;
	}

}



.client_content{
	&.plum_page{
		margin-top: 20px;

		.panel-body{
			padding: 0 40px;
		}
	}

	.space{
		//padding: 15px;
		border-top: 3px solid $main-color;
	}


	.header_cansel_btn{
		font-size: 11px;
		padding: 5px 8px;
	}

	.client_header{
		padding: 15px 20px;
		border-bottom: 1px solid #EEE;
		font-size: 120%;
		font-weight: bold;
		color:$main-color;
		line-height: 1;
		background-color: $thin-color;

		hr{
			display: none;
		}

		@include sp(){
			hr{
				display: block;
			}

			@include clearfix();
		}


		.back_top{
			margin-top: -9px;
		}
	}

	&.user_content{
		.space{
			border-top: 3px solid $user-color;
		}
		.client_header{
			color:$user-color;
			background-color: $user-thin-color;
		}
	}

	.index_head{
		margin-bottom: 10px;
	}

	.alertbox{
		margin-top: 0px;
	}

	.index_buttons {
		margin: 0 -15px;

		.index_button_list {
			list-style: none;
			max-width: 1000px;
			padding: 0;
			margin: 40px auto 0;
			@include not_sp {
				@include display(flex);
				@include flex-wrap(wrap);
			}

			li {
				padding: 0 15px;
				@include not_sp {
					@include flex-basis(33.33333333%);
					@include flex-basis(calc(100% / 3));

					// 1行目しかなければ引き伸ばす
					&:nth-of-type(-n + 2) {
						@include flex-grow(1);
					}
				}
				@include pc {
					@include flex-basis(25%);

					// 1行目しかなければ引き伸ばす
					&:nth-of-type(-n + 3) {
						@include flex-grow(1);
					}
				}

				a {
					display: inline-block;
					width: 100%;
					text-align: center;
					border: 1px solid $main-color;
					font-size: 115%;
					border-radius: 4px;
					background-color: $light-color;
					font-weight: bold;
					padding: 1.75em 0;
					margin-bottom: 20px;

					.label {
						display: inline-block;
						padding: 4px;
					}
				}
			}
		}
	}

	.index_btns{
		margin-top: 40px;
		a.index_btn{
			display: inline-block;
			width: 100%;
			line-height: 500%;
			text-align: center;
			border: 1px solid $main-color;
			font-size: 115%;
			border-radius: 4px;
			background-color: $light-color;
			font-weight: bold;
		}

		.label-anchor{
			display: inline-block;
			padding: 4px;
		}
	}

	.error_screen_logo{
		width: 300px;
		@include sp{
			width: 100%;
		}
	}


	.client_title {
		position: relative;
		padding: .75em 1em .75em 2em;
		border: 1px solid #ccc;
		border-top: 3px solid $main-color;
		background: -webkit-linear-gradient(top, #fff 0%, #f0f0f0 100%);
		background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
		box-shadow: 0 -1px 0 rgba(255, 255, 255, 1) inset;
		font-size: 20px;
		font-weight: bold;
		color:$main-color;
		&:after {
			position: absolute;
			top: 1em;
			left: .8em;
			z-index: 2;
			content: '';
			width: 10px;
			height: 10px;
			border: 3px solid $main-color;
			border-radius: 100%
		}

		.back_btn{
			margin-top: -5px;
			margin-right: -1px;
		}
	}

	.notice_box{
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-top: 15px;
		background-color: #f2dede;
		font-weight: bold;
	}
	.notice_box2{
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-top: 15px;
		background-color: #e1f2de;
		font-weight: bold;
	}



	.error_message,
	.simlation_error,
	.error_message_beri{
		background-color: $color-danger;
		padding: 5px 6px;
		margin-top: 7px;
		border-radius: 3px;
		p{
			font-size: 11px;
			color:white;
			line-height: 1.4;
			margin-bottom: 0;
		}
	}
	.error_message_beri{
		display: none;
	}
	.simlation_error{
		margin-left: 10px;
		margin-right: 10px;
		display: none;
	}

	.image_error_message{
		background-color: $color-danger;
		padding: 5px 6px;
		margin-top: 7px;
		border-radius: 3px;
		color:#FFF;
	}

	.upload_error{
		display: none;
	}

	.message{
		border: 1px solid $color-message;
		background-color: #FFF;
		padding: 5px 6px;
		margin-bottom: 7px;
		border-radius: 3px;
		p{
			font-size: 12px;
			//color:white;
			color:$color-message;
			line-height: 1.4;
			margin-bottom: 0;
			//font-weight: bold;
		}
	}

	.form_area{
		margin-left: -15px;
		margin-right: -15px;
		//padding: 10px;

		margin-top: 20px;
		margin-bottom: 20px;

		&.confirm{
			padding: 10px;
			border: 2px solid $color-info;
			background-color: #fff;
			box-shadow:0px 0px 4px -1px #000000;
		}

		&.detail{
			padding: 10px;
			border: 2px solid $main-color;
			background-color: #fff;
			box-shadow:0px 0px 4px -1px #000000;
		}

		&.check{
			padding: 10px;
			border: 2px solid $gray-main-color;
			background-color: #fff;
			box-shadow:0px 0px 4px -1px #000000;
		}

		&.account{
			padding: 10px;
			border: 2px solid red;
			background-color: #f2dede;
			box-shadow:0px 0px 4px -1px #000000;
			margin: 10px;

			@include sp{
				margin: 0;
				margin-top: 10px;

				.btn-lg{
					font-size: 14px;
					padding: 10px;
				}
				.btn-sm{
					font-size: 10px;
				}
			}

			h5{
				text-align: center;
				color:red;
				font-size: 16px;
				font-weight: bold;

				@include sp{
					margin-top: 0;
					font-size: 12px;
				}

			}
		}

		&.user-confirm{
			padding: 10px;
			border: 2px solid $color-warning;
			background-color: #fff;
			box-shadow:0px 0px 4px -1px #000000;
		}

		&.wait_message_box{
			padding: 10px;
			border: 2px solid $main-color;
			background-color: #FFF;
			box-shadow:0px 0px 4px -1px #000000;
			margin: 10px;

			@include sp{
				margin: 0;
				margin-top: 10px;

				.btn-lg{
					font-size: 14px;
					padding: 10px;
				}
			}
			h5{
				font-size: 20px;
				text-align: center;
				color:$main-color;
				font-weight: bold;

				@include sp{
					margin-top: 0;
					font-size: 16px;
				}
			}

			&.confirm_crepico {
				background-color: #e7f8f4;
				border-style: double;
				border-width: 5px;
				line-height: 1.25;
			}
		}
	}

	.form_table{
		box-sizing: border-box;
		-webkit-box-sizing:border-box; /* Safari */

		margin-top: 10px;
		&:first-child{
			margin-top: 0;
		}

		th,td{
			padding: 10px;
			border: 1px solid $light-color;
			//border: 1px solid $gray-light-color;
			box-sizing: border-box;
			-webkit-box-sizing:border-box; /* Safari */

			@include sp{
				display: block;
				width: 100% !important;
			}


		}
		tr{
			box-sizing: border-box;
			-webkit-box-sizing:border-box; /* Safari */
		}
		th{
			width: 175px;
			border-left: 0;
			color:$main-color;

			position: relative;

			@include sp{
				background-color: $thin-color;
				border-left: 1px solid $light-color;
			}



			.hissu{
				font-size: 10px;
				color:white;
				position: absolute;
				// right: 8px;
				// top: 50%;
				// margin-top: -9px;
				background-color: $color-danger;
				padding: 4px;
				line-height: 1;

				right: 5px;
				top: 5px;
				margin-top: 0px;
			}
		}

		&.form_table_sm{
			th{
				width: 140px;
			}
		}
		td{
			border-right: 0;

			@include sp{
				margin-bottom: 10px;
				border-right: 1px solid $light-color;
				border-top: 0;
			}

			.error_message{
				display: none;
			}
		}
		tr{
			&:nth-child(odd) {
				//background-color: #f9f9f9;
				background-color: #FFF;
				@include sp{
					background-color: #FFF;
				}
			}
			&:nth-child(even) {
				background-color: $thin-color;
				//background-color: $gray-thin-color;
				@include sp{
					background-color: #FFF;
				}
			}
			&.odd{
				background-color: #FFF;
			}
			&.even{
				background-color: $thin-color;
			}

			&.error{
				//border: 2px solid lighten($color-danger,15%);
				border: 2px solid $color-danger;
				th,td{
					//background-color: lighten($color-danger,49%);
					//border: 1px solid $color-danger;
				}
			}

			
		}

		.form-span{
			display: inline-block;
			padding-left: 5px;
			padding-right: 5px;
		}
		.form-unit{
			display: inline-block;
			padding-left: 5px;
			padding-right: 10px;
			@include sp{
				padding-right: 5px;
				padding-left: 3px;
			}
		}

		.form-span-before{
			display: inline-block;
			padding-left: 0px;
			padding-right: 10px;
		}
		.form-span-after{
			display: inline-block;
			padding-left: 10px;
			padding-right: 0px;
		}

		.sale_item_tr{
			.item_empty_btn{
				position: absolute;
				// top:66%;
				bottom:5px;
				right: 5px;
				padding: 5px;
				padding-top: 2px;
    			padding-bottom: 2px;
				margin-top: -12px;
			}
		}

		.items{
			.item_flex{
				display: flex;
			}
			.item_category{
				margin-bottom: 5px;
				font-size: 11px;
			}

			.item_btn{
				width: 100px;

				a{
					display: block;
				}
			}
			.item_name{
				width: 100%;
				padding-left: 1px;
				padding-right: 10px;
				flex: 1;
			}
			.item_amount{
				width: 60px;
				padding-right: 5px;
			}
			
			.item_amount_unit{
				display: inline-block;
				padding-right: 5px;
				padding-top: 5px;
			}
			
			.item_price{
				width: 120px;
				padding-right: 5px;
			}
			.item_price_unit{
				display: inline-block;
				padding-top: 5px;
			}
		}


		#birthday_view{
			margin-left: 20px;
			@include sp{
				margin-left: 5px
			}
		}


		.tel-form{
			.sample{
				@include sp{
					display: inline-block;
					margin-top: 5px;
				}
			}
		}

		.partner_radio{
			@include sp{
				font-size: 11px;
				padding:6px 6px;
				width: auto;
			}
		}

		#live_people_count{
			@include sp{
				select{
					width: 80px;
					display: inline-block;
				}
			}
		}

		#other_maintainer{
			@include sp{
				margin-top: 10px;
			}
		}

		#now_work-1 + label,
		#now_work-2 + label,
		#now_work-3 + label{
			
			@include not_sp{
				br{
					display: none;
				}
			}
			@include sp{
				br{
					display: inline-block;
				}
			}

			@include sp{
				font-size: 11px;
				padding:6px 5px;
				/*
				font-size: 10px;
				padding:6px 0px;
				*/
			}
		}

		#beri_type{
			@include sp{
				.w100{
					width: 80px;
				}
			}
		}

		.hope_date{

			@include sp{
				.show-calender-btn{
					margin-top: -2px;
				}
				.select-date{
					width: 200px;
				}
				.select-hour1{
					width: 80px;
					display: inline-block;
				}
				.select-minute1{
					width: 80px;
					display: inline-block;
				}
				.select-hour2{
					margin-top: 5px;
					width: 80px;
					display: inline-block;
				}
				.select-minute2{
					margin-top: 5px;
					width: 80px;
					display: inline-block;
				}
			}
		}

	}



	.form-file{
		margin-top: 10px;

		.file-empty{
			padding: 40px;
			background-color: #efefef;
			border: 2px dotted #9a9a9a;
			text-align: center;

			font-size: 150%;
			font-weight: bold;
			//border-radius: 4px;

			color:#ccc;

			cursor: pointer;

			i{
				

			}

			@include sp{
				padding: 20px 10px;
				font-size: 12px;
			}
		}

		.file-uploaded{
			border: 1px solid #ccc;
			text-align: center;
			padding: 10px;
			//border-radius: 4px;
			background-color: #FFF;

			img{
				height: 200px;
				@include sp{
					width: 100%;
					height: auto;
				}
			}

			&.file-uploaded_confirm{
				img{
					height: auto;
					max-width:100%;
				}
			}
		}

	}


	.form-btns {
		display:inline-block;
		margin:0;
		padding:0;
		clear:both;
		
		vertical-align: bottom;

		li {
		    display:inline-block;
		    position:relative;
		    float:left;
		    list-style:none;
		    border:solid 1px #ddd;
		    border-right:none;
		    overflow:hidden;
		}
		li:first-child {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		li:last-child {
		    border-right:solid 1px #ddd;
		    border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		label {
		    display:block;
		    min-width:3em;
		    text-align:center;
		    background:#eee;
		    background:-webkit-gradient(linear, left top, left bottom, from(#FFF),to(#EEE));
		    //background:-moz-linear-gradient(top, #fff, #eee);
		    border:solid 1px rgba(255,255,255,0.2);
		    padding:8px 10px;
		    text-shadow:0 1px 0 rgba(255,255,255,0.5);
		    margin-bottom: 0;
		}
		li:first-child label {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		li:last-child label {
		    border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		input[type=radio] {
		    position:absolute;
		    opacity:0;
		    width:100%;
		    height:100%;
		    margin:0;
		    cursor:pointer;
		}
		input[type=radio]:disabled {
			cursor: not-allowed;
		} 
		input[type=radio]:checked {
		    cursor:auto;
		}
		input[type=radio]:checked + label {
		    color:#fff;
		    text-shadow:0 -1px 0 rgba(0,0,0,0.2);
		    border:solid 1px rgba(0,0,0,0.1);
		    background:#666;
		    background:-webkit-gradient(linear, left top, left bottom, from(#313131),to(#626262));
		    //background:-moz-linear-gradient(top, #333, #666);
		}
		input[type=radio]:not(:checked):disabled + label,
		input[type=checkbox]:not(:checked):disabled + label {
			color: #888;
			background: #ddd;
			background: -webkit-gradient(linear, left top, left bottom, from(#eee),to(#ddd));
		}

		input[type=checkbox] {
		    position:absolute;
		    opacity:0;
		    width:100%;
		    height:100%;
		    margin:0;
		    cursor:pointer;
		}
		input[type=checkbox]:checked {
		    cursor:auto;
		}
		input[type=checkbox]:checked + label {
		    color:#fff;
		    text-shadow:0 -1px 0 rgba(0,0,0,0.2);
		    border:solid 1px rgba(0,0,0,0.1);
		    background:#666;
		    background:-webkit-gradient(linear, left top, left bottom, from(#313131),to(#626262));
		    //background:-moz-linear-gradient(top, #333, #666);
		}

		&.btn_wid100{
			label{
				width: 100px;
			}
		}
		&.btn_wid80{
			label{
				width: 80px;
			}
		}
	}

	.form-dl{
		margin-bottom: 7px;
		display:flex;
		align-items:center;

		@include sp{
			display: block;
		}

		dt{
			width: 100px;
			padding-right: 10px;
			text-align: right;

			@include sp{
				width: 100%;
				text-align: left;
				font-size: 11px;
			}
		}
		dd{
			flex: 1;
			@include sp{
				width: 100%;
				text-align: left;
			}
		}
	}
	.form-address{
		margin-bottom: 7px;
	}

	.total_view{
		//padding: 10px;
		border:2px solid $main-color;
		margin: 20px 10px;
		border-radius: 5px;

		@include sp{
			margin: 10px 0px;
			border-radius: 4px;
		}
		//background-color: $gray-thin-color;

		h3:first-child{
			margin-top: 10px;
		}
	}
	.pdf_box{
		h3{
			margin-top: 0;
		}
	}

	.mail_box{
		h3{
			margin-top: 0;
		}
		.mail_sended_list{
			& > div:not(:last-of-type) {
				opacity: .4;
			}
		}
		input.mail{
			width: 290px;
			display: inline-block;
			vertical-align: top;
		}
		input.name{
			width: 80px;
			display: inline-block;
			vertical-align: top;
		}

		a{
			vertical-align: top;
		}
		.mail_form{
			span{
				display: inline-block;
				margin-top: 5px;
			}
		}
		
	}

	.sms_box {
		#send_sms_btn.disabled::after {
			content: '中...';
		}
	}

	.submit_btn_area{
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.coolingoff{
		h3 span{
			color:red;
		}

		.text{
			color:red;
		}
	}
	
	.text_wrap{
		max-width:760px;
		margin: 0 auto;

		&.text_box {
			padding: 15px 13px;
			border: 3px solid #298470;
			border-radius: 4px;
		}

		.cmpt_title {
			background-color: #a32d30;
			color: white;
			font-weight: bold;
			padding: 2px 6px;
			border: none;
			margin: 0 0 10px;

			& + section > .cmpt_subtitle:first-child {
				margin-top: -10px;
			}
		}
		.cmpt_subtitle {
			background-color: #f7dbdb;
			font-weight: normal;
			padding: 2px 6px;
			border: none;
			margin: 0 0 10px;
		}

		/* components */
		[class*="cmpt-text"] {
			ol, ul {
				padding-left: 1.5em;

				li {
					margin-bottom: 1em;
				}
			}
			ol.parenthesized {
				list-style: none;
				padding-left: 2em;
				counter-reset: li 0;
				& > li {
					counter-increment: li;

					& > p:first-child::before {
						content: "（"counter(li)"）";
						display: inline-block;
						text-align: center;
						margin-left: -2.5em;
						width: 2.5em;
					}

					& > p[data-marker]:first-child::before {
						content: "（"attr(data-marker)"）";
					}
				}
			}
			.clause {
				dt {
					margin-bottom: 10px;
				}
				ul {
					list-style: none;
					padding-left: 1em;
				}
			}
			.contact {
				dt {
					font-weight: normal;
					margin-bottom: 0;
				}
				dd {
					padding-left: 1.5em;
				}
			}
			.border_danger {
				border: 2px solid #d2322d;
				padding: 2px 5px;
				margin-bottom: 12px;

				&.dotted {
					border-style: dotted;
				}

				& > .cmpt_title {
					margin: 0 -3px 10px;
				}
			}
			.title_danger {
				border: 2px solid #d2322d;
				color: #d2322d;
				line-height: 1.25;
				padding: 0 3px;
			}
		}
		.cmpt-text3 {
			table {
				margin-bottom: 1em;
			}
			.annotation {
				dt {
					font-weight: normal;
				}
			}
		}
		.cmpt-text6 {
			.underline_title {
				text-decoration: underline;
				font-size: 1.25em;
			}
			ul {
				list-style-type: disc;
				margin-bottom: 1em;
			}
		}
	}

	.ti-1 {
		text-indent: 1em;
		margin-bottom: 10px;

		& + .ti-1 {
			margin-top: -10px;
		}
	}
	.mb-0 {
		margin-bottom: 0;
	}

	.digital_contract{
		h3{
			background: none;
			border: none;
			font: 18px;
		}

		.agree_btn_area{
			list-style: none;
			margin:0;
			padding: 0;
			text-align: center;

			padding-top: 20px;
			padding-bottom: 20px;

			li{
				list-style:none;
				margin:0;
				padding: 0;
				vertical-align: top;

				display: inline-block;

				width: 180px;
				margin: 0 20px;

				span{
					display: block;
					margin-top: 5px;
					font-size: 11px;
					color:#666;
				}

				a{
					display: block;
					text-align: center;
					font-size: 16px;
					font-weight: bold;
					text-decoration: none;
					color:#333;
				}

				&.cancel{
					a{
						border:1px solid #ccc;
						background-color: #e5e5e5;
						padding: 10px;
						border-radius: 4px;

						&:hover{
							opacity:0.7;
						}
					}
				}

				&.agree{
					a{

						border:1px solid #ccc;
						background-color: #e5e5e5;
						padding: 10px;
						border-radius: 4px;

						&:hover{
							opacity:0.7;
						}

						.icon_check{
							display: none;
						}
						.icon_no_check{
							display: inline-block;
						}
					}
					&.checked{
						a{
							border:1px solid $main-color;
							background-color: $light-color;
							padding: 10px;
							border-radius: 4px;
							color:$main-color;

							.icon_check{
								display: inline-block;
							}
							.icon_no_check{
								display: none;
							}
						}
					}
				}



			}
		}
		
		.digital_block{
			border: 1px solid #ccc;
			padding: 15px;
			h4{
				font-size: 16px;
			}
			p{
				padding-left: 15px;
			}
			margin-bottom: 10px;
		}

		
	}

	.text-scroll{
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;

		padding: 10px;
		border: 1px solid $main-color;

		height: 320px;
	}
	.text-scroll2{
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;

		padding: 10px;
		border: 1px solid $main-color;

		height: 480px;
	}

	.check_area{
		padding-top: 15px;

		.checkbox-custom{
			display: inline-block;
		}
	}

	#item_list_modal{
		.item_list,.item_list_category{
			padding: 0;
			margin: 0;
			li{
				border: 1px solid #ccc;
				padding: 0;
				margin: 0;
				padding: 10px;
				list-style: none;
				background-color: #f7f7f7;

				margin-bottom: 5px;
				&:last-child{
					margin-bottom: 0;
				}

				table{
					width: 100%;

					.name{
						width: 58%;
					}
					.month{
						width: 12%;
					}
					.price{
						width: 15%;
					}
					.button{
						width: 15%;
					}
				}
			}
		}
		.item_list_category{
			margin-top: 10px;
		}
	}

	.search_area{
		margin-top: 20px;
		.panel{
			border-left: 1px solid #eee;
			border-right: 1px solid #eee;
			border-bottom: 1px solid #eee;

			th{
				background-color: #e7f8f4;
    			width: 160px;
			}
		}
	}


	.calender-picker{
		select{
			font-size: 12px;
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.agree_area_btns{
		ul{
			text-align: center;

			li{
				display: inline-block;
				width: 240px;
				padding-left: 20px;
				a{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
	}

	.change_password_box{
		width: 760px;
		margin: 0 auto;
		padding-top: 40px;
	}

	.individual_wrap{
		.panel-body{
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			border-bottom: 1px solid #ccc;

			th{
				background-color: #e7f8f4;
				font-size: 12px;
				width: 130px;
				padding: 5px;
			}
			td{
				font-size: 12px;
				padding: 5px;
			}
		}

		.btn-vsm{
			font-size: 11px;
			padding: 3px;
			line-height: 1;
			margin-top: 6px;
		}

		#tel_change_modal{
			.form-dl{
				margin-bottom: 10px;

				dt{
					margin-bottom: 5px;
				}
			}
			.form-dl_last{
				margin-bottom: 0px;
			}
			.error_message{
				p{
					margin-bottom: 0;
					color:red;
					font-size: 11px;
					margin-top: 10px;
				}
			}
		}
	}

	#sign_area{
		margin-top: 20px;
		margin-bottom: 20px;

		.sign_box{
			width: 500px;
			margin: 0 auto;
		}

		.sign_border{
			height: 200px;
			border: 1px solid #ccc;

			canvas{
				width: 500px;
				height: 200px;
			}
		}
		.sign_btns{
			margin-top: 10px;
		}
	}

	.select_bank{
		padding: 40px;

		
		ul{
			margin: 0;
			padding: 0;
			border: 5px solid #ccc;
		}

	}


	h3{
		border-top: 1px solid $main-color;
		border-bottom: 1px solid $main-color;
		padding: 5px;
		//border-left: 3px solid $main-color;
		padding-left: 4px;
		font-size: 120%;
		color:$main-color;
		font-weight: bold;
		background-color: $thin-color;

		span{
			border-left: 3px solid $main-color;
			display: inline-block;
			padding: 2px;
			padding-left: 6px;
		}

		&.red_title{
			background-color: red;
			color:white;

			border-top: 1px solid red;
			border-bottom: 1px solid red;

			span{
				border-left: 3px solid white;
			}
		}
	}
	h4{
		font-weight: bold;
		border-left: 3px solid $main-color;
		font-size: 14px;
		padding: 2px;
		padding-left: 6px;
		margin: 0;

		&.red_title{
			color:red;
			border-left: none;
			border: 2px solid red;
			margin-bottom: 10px;
		}
	}

	h5{
		&.red_title{
			color:red;
		}
		&.bold{
			font-weight: bold;
		}
	}

	.indent_1{
		text-indent:-1em;
		margin-left: 1em;
	}
	.indent_2{
		text-indent:-1em;
		margin-left: 1em;
	}
	.indent_3{
		text-indent:-1em;
		margin-left: 2em;
	}
	.color_red{
		color:red;
	}

	.tal-c{
		text-align: center;
	}
	.tal-r{
		text-align: right;
	}
	.tal-l{
		text-align: left;
	}

	.form-ib{
		display: inline-block;
	}

	.fwb{
		font-weight: bold;
	}

	.w320{
		width: 320px;
	}
	.w250{
		width: 250px;
	}
	.w200{
		width: 200px;
	}
	.w120{
		width: 120px;
		@include sp{
			width: 85px;
		}
	}
	.w100{
		width: 100px;
	}
	.w80{
		width: 80px;
		@include sp{
			width: 65px;
		}
	}
	.w60{
		width: 60px;
	}
	.w50{
		width: 50px;
	}
	.w40{
		width: 40px;
	}

	.h120{
		height: 120px;
	}

	.select-year{
		@include sp{
			width: 85px;
		}
	}
	.select-month{
		@include sp{
			width: 65px;
		}
	}
	.select-day{
		@include sp{
			width: 65px;
		}
	}

	#mailAddressArea,
	.mail_form {
		input, select {
			width: 160px;
			text-transform: lowercase;
		}
		.form-ib {
			vertical-align: text-top;
		}
		.mail_address_at {
			padding: 6px 0;
		}
		.show_other {
			width: 6em;
			padding: 6px;
		}
	}

	.mail_box .mail_form {
		.mail_address_input {
			display: table;

			dt, dd {
				display: table-cell;
				font-weight: normal;
			}
			.mail_address_label {
				width: 110px;
				text-align: right;
				margin: 0 .25em 0 0;
			}
			.mail_address_at {
				padding: 8px 0;
				margin-top: 0;
			}
			#send_mail_btn {
				vertical-align: text-top;
			}
		}
	}

	.information_area,
	.manual_area {
		.area_header {
			border-bottom: solid 1px #eee;
			margin-bottom: 20px;

			h2 {
				font-size: 24px;
				font-weight: bold;
				color: $main-color;
				margin-top: 0;
			}
		}
	}

	.information_area {
		.info_header {
			padding: 10px 15px;

			.info_title {
				.info_vc_title {
					word-break: break-all;
				}
			}
		}
		.info_date {
			color: #aaa;
			font-size: 12px;
		}
		.info_title {
			font-size: 18px;
		}
		.info_text {
			white-space: pre-line;
			margin-bottom: 0;
		}
	}

	.manual_area {
		.manual_list {
			padding-left: 0;
			list-style: none;

			li + li {
				margin-top: 20px;
			}


			.manual_box {
				display: block;
				text-decoration: none;
				color: #222;
				transition: border-color .125s;
				margin: 0;

				&:hover {
					border-color: $color-primary;

					.manual_icon {
						color: #c15d44;
					}
					.manual_name {
						color: $color-primary;
					}
				}
				&:not(:hover) {
					border-color: #ddd;
				}

				.manual_icon {
					font-size: 32px;
					margin-top: 10px;
					transition: color .125s;
				}
				.manual_name {
					font-weight: bold;
					word-break: break-all;
					transition: color .125s;
				}
				.manual_size {
					font-size: 12px;
					color: #888;
				}
				.manual_time {
					font-size: 12px;
				}
			}
		}
		.qa_container {
			border: 1px solid #e3e3e3;
			border-radius: 4px;
			padding: 15px;
			@include not_sp {
				margin-left: -15px;
			}

			.qa_list {
				dt, dd {
					display: table;
					width: 100%;
					padding: 6px;

					& > span {
						display: table-cell;
					}

					.faq_no {
						font-weight: normal;
						width: 1px;
						padding-right: .75em;
						white-space: nowrap;
						font-family: $monospace;
						font-size: 14px;
					}
				}

				dt {
					counter-increment: qa;
					background-color: $thin-color;
					color: $main-color;
					border-radius: 4px;
					margin-top: 15px;
				}

				dd {
					.faq_no {
						color: #be3b3b;
					}
					.answer {
						white-space: pre-line;
					}
				}
			}
		}
		.storedfile_list {
			@include display(flex);
			@include flex-wrap(wrap);

			.manual_box {
				@include display(flex);
				@include flex-flow(column);
				@include justify-content(center);
				
				&:hover {
					.manual_icon {
						color: $color-primary;
					}
				}
			}

			li {
				@include display(flex);
				margin-bottom: 20px;

				& + li {
					margin-top: 0;
				}

				.manual_box {
					@include flex(1);
				}
			}
		}
	}
	
	.pe-none {
		pointer-events: none;
	}


	.emphasis_left {
		font-size: 14px;
		font-weight: bold;
		border-color: #999 #ddd #999 #999;
		border-style: solid;
		border-width: 2px 1px 2px 2px;
	}
	.emphasis_right {
		font-size: 14px;
		font-weight: bold;
		border-color: #999 #999 #999 #ddd;
		border-style: solid;
		border-width: 2px 2px 2px 1px;
	}


}

.modal{
	.modal-body{
		.zip_list{
			min-height: 50px;
		}
	}
}

.client_content.modal-open{
	.datepicker {
	    z-index: 1060 !important;
	}
}

#set_cancel_modal .modal-content{
	border:3px solid $color-danger;
	.modal-title{
		color: $color-danger;
		border-left: $color-danger;
	}
}

#tel_reset_modal{
	.beri_modal_title{
		margin-bottom: 9px;
		padding-bottom: 4px;
		border-bottom: 1px solid #666;
		font-weight: bold;
	}
	.error_message{
		background-color: #FFF;
		padding: 0;
		margin: 0;
		p{
			color:red;
			margin-bottom: 10px;
		}
	}
}

/* テスト環境 */
body.client_content.staging {
	.client_login form [type="submit"] {
		background-color: #575757;
		border-color: #575757 #575757 #3D3D3D;
	}
	h4 {
		border-color: #575757;
	}
	.client_title {
		color: #575757;

		&, &::after {
			border-color: #575757;			
		}
	}
	.search_area {
		.panel {
			th {
				background-color: #f0f0f0;
			}
		}
	}
	.individual_wrap {
		.panel-body {
			th {
				background-color: #f0f0f0;
			}
		}
	}
	.space {
		border-top-color: #000;

		.client_header {
			color: #FFF;
			background-color: #3C3C3C;
		}
	}
	.panel-primary > .panel-heading {
		background-color: #575757;
		border-bottom-color: #757575;
	}
	.form_area.confirm {
		border-color: #88a6af;
	}
	.form_area.user-confirm {
		border-color: #a18e72;
	}

}
