
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

body {
	color:#000;
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
}

/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

html, body{
	background-color: #FFF;
	font-feature-settings : "pkna";
}

//========================================================

.btn-big{
  padding: 26px;
  font-size: 20px;

  @include sp{
    padding: 13px;
    font: 14px;
  }
}
body .btn.btn-radius{
  border-radius: 5px !important;
}

.table1{
  border-top: 1px solid #ccc;
  border-left:1px solid #ccc;

  td,th{
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding:10px;
  }
  th{
    background-color: #F9F9F9;
  }
}

body .static{
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  padding: 10px;
  background-color: #FFF;
  border: 2px solid red;
  color:red;
  font-weight: bold;
  text-align: center;

  span{
    display: inline-block;
    position: absolute;
    top:-16px;
    background-color: red;
    padding: 3px;
    line-height: 1;
    left: -2px;
    color:#FFF;
    font-size: 10px;
    font-weight: bold;
  }
}