// COMMON
// -----------------------------------------------------------------------------
/* Progress bar overwrite style */
.progress-bar {
	background: $color-primary;
}

.progress {
	.progress-bar {
		box-shadow: none;
		border-radius: 4px;
	}
}

// DARK
// -----------------------------------------------------------------------------
/* Progress bar default style */
.progress {
	background: $pb-default;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4) inset;
}

// LIGHT
// -----------------------------------------------------------------------------
/* Progress bar light style */
.progress.light {
	background: #f6f7f8;
	@include background(linear-gradient($pb-light-line, $pb-light-line 10%, $pb-light 11%));
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}

// ROUNDEDNESS
// -----------------------------------------------------------------------------
/* Progress bar roundness generic */
.progress-squared {
	&,
	& .progress-bar {
		border-radius: 0 !important;
	}
}

// SIZES
// -----------------------------------------------------------------------------
/* Progress bar sizes */
.progress-xs {
	height: $pb-xs;

	&,
	& .progress-bar {
		border-radius: $pb-xs;
	}

	.progress-bar {
		direction: ltr !important;
		text-indent: -9999px;
	}

	&.progress-half-rounded {
		&,
		& .progress-bar {
			border-radius: floor($pb-xs / 3);
		}
	}

	&.progress-striped {
		.progress-bar {
			background-size: 15px 15px;
		}
	}
}

.progress-sm {
	border-radius: $pb-sm;
	height: $pb-sm;

	&,
	& .progress-bar {
		border-radius: $pb-sm;
	}

	.progress-bar {
		font-size: 10px;
		line-height: $pb-sm;
	}

	&.progress-half-rounded {
		&,
		& .progress-bar {
			border-radius: floor($pb-sm / 3);
		}
	}

	&.progress-striped {
		.progress-bar {
			background-size: 20px 20px;
		}
	}
}

.progress-md {
	border-radius: $pb-md;
	height: $pb-md;

	&,
	& .progress-bar {
		border-radius: $pb-md;
	}

	.progress-bar {
		font-size: 11px;
		line-height: $pb-md;
	}

	&.progress-half-rounded {
		&,
		& .progress-bar {
			border-radius: floor($pb-md / 3);
		}
	}


	&.progress-striped {
		.progress-bar {
			background-size: 25px 25px;
		}
	}
}

.progress-lg {
	border-radius: $pb-lg;
	height: $pb-lg;

	&,
	& .progress-bar {
		border-radius: $pb-lg;
	}

	.progress-bar {
		line-height: $pb-lg;
	}

	&.progress-half-rounded {
		&,
		& .progress-bar {
			border-radius: floor($pb-lg / 3);
		}
	}

	&.progress-striped {
		.progress-bar {
			background-size: 30px 30px;
		}
	}
}

.progress-xl {
	border-radius: $pb-xl;
	height: $pb-xl;

	&,
	& .progress-bar {
		border-radius: $pb-xl;
	}

	.progress-bar {
		line-height: $pb-xl;
	}

	&.progress-half-rounded {
		&,
		& .progress-bar {
			border-radius: floor($pb-xl / 3);
		}
	}

	&.progress-striped {
		.progress-bar {
			background-size: 35px 35px;
		}
	}
}

// STATES
// -----------------------------------------------------------------------------
/* Progress bar states */
@each $state in $states {
	.progress {
		.progress-bar-#{nth($state,1)} {
			background-color: #{nth($state,2)};
		}
	}
}

// CIRCULAR BAR
// -----------------------------------------------------------------------------
.circular-bar {
	margin-bottom: 25px;
	.circular-bar-chart {
		position: relative;
	}
	strong {
		display: block;
		font-weight: 600;
		font-size: 18px;
		line-height: 30px;
		position: absolute;
		top: 35%;
		width: 80%;
		left: 10%;
		text-align: center;
	}
	label {
		display: block;
		font-weight: 100;
		font-size: 17px;
		line-height: 20px;
		position: absolute;
		top: 50%;
		width: 80%;
		left: 10%;
		text-align: center;
	}
}