.hide_item{
	display: none;
}

.sort_box{
	float: right;

	.dropdown-menu{
		margin-left: -60px;
	}
}
.download_box{
	float: right;
	margin-right: 20px;
	.dropdown-menu{
		margin-left: -60px;
	}
}

.datepicker{
	z-index:1020 !important;
}

.pdf_dl_box{
	padding: 15px;
	border: 1px solid #ccc;
	background-color: #f7f7f7;
	h4{
		text-align: center;
		margin-top: 0;
		border-left: 0 !important;
	}
}