/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
	background: $color-primary;

	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;

	width: 100%;
	height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px $color-primary, 0 0 5px $color-primary;
	opacity: 1.0;

	@include transform( rotate(3deg) translate(0px, -4px) );
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
	display: block;
	position: fixed;
	z-index: 1031;
	top: 15px;
	right: 15px;
}

#nprogress .spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;

	border: solid 2px transparent;
	border-top-color: #29d;
	border-left-color: #29d;
	border-radius: 50%;

	@include animation(nprogress-spinner 400ms linear infinite);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@include keyframes(nprogress-spinner) {
	0% {
		@include transform(rotate(0));
	}
	100% {
		@include transform(rotate(360deg));
	}
}