// PRICING TABLES
// -----------------------------------------------------------------------------
.pricing-table {
	margin: 25px 0;
	padding-left: 0;
	text-align: center;
	ul {
		list-style: none;
		margin: 20px 0 0 0;
		padding: 0;
	}
	li {
		border-top: 1px solid #ddd;
		padding: 10px 0;
	}
	h3 {
		background-color: #eee;
		border-radius: 2px 2px 0 0;
		font-size: 20px;
		font-weight: normal;
		margin: -20px -20px 50px -20px;
		padding: 20px;
		span {
			background: #FFF;
			border: 5px solid #FFF;
			border-radius: 100px;
			box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
			color: $color-primary;
			display: block;
			font: bold 25px / 100px Georgia, Serif;
			height: 100px;
			margin: 20px auto -65px;
			width: 100px;
		}
	}
	.most-popular {
		border: 3px solid #CCC;
		box-shadow: 11px 0 10px -10px rgba(0, 0, 0, 0.1), -11px 0 10px -10px rgba(0, 0, 0, 0.1);
		padding: 30px 20px;
		top: -10px;
		z-index: 2;
		h3 {
			background-color: $color-primary;
			color: #FFF;
			padding-top: 30px;
		}
	}
	.plan-ribbon-wrapper {
		height: 88px;
		overflow: hidden;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 85px;
	}

	.plan-ribbon {
		@include transform(rotate(45deg));
		@include background-image(linear-gradient(top, #bfdc7a, #8ebf45));
		background-color: #bfdc7a;
		box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
		color: #333;
		font-size: 14px;
		left: -5px;
		padding: 7px 0;
		position: relative;
		text-align: center;
		top: 15px;
		width: 120px;
		&:before {
			left: 0;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			bottom: -3px;
			content: "";
			position: absolute;
		}
		&:after {
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			bottom: -3px;
			content: "";
			position: absolute;
			right: 0;
		}
	}
	.plan {
		background: #FFF;
		border: 1px solid #ddd;
		border-radius: 5px;
		color: #333;
		margin-bottom: 35px;
		margin-right: 0;
		padding: 20px;
		position: relative;
	}
	.btn {
		margin-top: 5px;
	}
}

// DARK
// -----------------------------------------------------------------------------
/* dark */
html.dark {
	.pricing-table {
		li {
			border-top-color: $dark-color-2;
		}
		h3 {
			background-color: $dark-color-2;
			text-shadow: none;
			span {
				background: $dark-color-4;
				border-color: $dark-color-5;
				box-shadow: 0 5px 20px $dark-color-5 inset, 0 3px 0 $dark-color-3 inset;
				color: $color-default;
			}
		}
		.most-popular {
			border-color: $dark-color-3;
			h3 {
				background-color: $dark-color-3;
				color: $dark-default-text;
				text-shadow: none;
			}
		}

		.plan-ribbon {
			background-color: $dark-color-3;
		}
		.plan {
			background: $dark-color-3;
			border: 1px solid $dark-color-3;
			color: $dark-default-text;
			text-shadow: none;
		}
	}
}