.media-gallery {

	ul.mg-folders {
		list-style: none;
		padding: 0;
		margin: 0;

		& > li {
			display: block;
			position: relative;

			a.menu-item {
				white-space: nowrap;
				text-overflow: ellipsis;
				padding-right: 80px;

				i {
					margin-right: 5px;
					width: 16px;
				}
			}

			.item-options {
				position: absolute;
				padding: 10px 0;
				right: 0;
				top: 0;
				opacity: 0;

				-webkit-transition: opacity 0.1s ease-in;
				-moz-transition: opacity 0.1s ease-in;
				transition: opacity 0.1s ease-in;

				a:hover {
					text-decoration: none;
				}
			}

			&:hover {
				.item-options {
					opacity: 1;
				}
			}

		}
	}

	ul.mg-tags {
		@include clearfix;
	    list-style: none;
	    padding: 0;
	    margin: 10px 0 0;

		& > li {
			float: left;
			margin-right: 5px;
			margin-bottom: 5px;

			a {
				display: block;
				padding: 2px 7px;
				font-size: 11px;
				background-color: #171717;
				-moz-border-radius: 5px;
				-webkit-border-radius: 5px;
				border-radius: 5px;
				color: #666;

				&:hover {
					color: #fff;
					text-decoration: none;
					background-color: $color-primary;
				}

			}
		}
	}

	.mg-files {
		padding: 5px 0 30px;

		.thumbnail {
			padding: 10px;
			border-radius: 5px;
			margin-bottom: 30px;

			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);

			.thumb-preview {
				position: relative;
				z-index: 2;

				.thumb-image {
					display: block;
					overflow: hidden;
				}

				img {
					width: 100%;

					-webkit-transition: all 0.1s linear;
					-moz-transition: all 0.1s linear;
					transition: all 0.1s linear;
					border-radius: 5px;
				}

				.mg-thumb-options {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(#000, 0.7);
					visibility: hidden;
					overflow: hidden;

					.mg-zoom {
						position: absolute;
						top: 0;
						right: 0;
						padding: 5px 15px 10px;
						@include font-size(22);
						color: #fff;
						background-color: $color-primary;
						border-radius: 0 0 0 15px;
						cursor: pointer;

						-webkit-transition: -webkit-transform 0.1s linear 0.1s;
						-moz-transition: -moz-transform 0.1s linear 0.1s;
						-ms-transition: -ms-transform 0.1s linear 0.1s;
						transition: transform 0.1s linear 0.1s;

						-webkit-transform: translate(100%, 0);
						-moz-transform: translate(100%, 0);
						-ms-transform: translate(100%, 0);
						transform: translate(100%, 0);
					}

					.mg-toolbar {
						@include clearfix;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						background-color: $color-primary;
						color: #fff;
						padding: 6px 10px;

						-webkit-transition: -webkit-transform 0.1s linear 0.1s;
						-moz-transition: -moz-transform 0.1s linear 0.1s;
						-ms-transition: -ms-transform 0.1s linear 0.1s;
						transition: transform 0.1s linear 0.1s;

						-webkit-transform: translate(0, 100%);
						-moz-transform: translate(0, 100%);
						-ms-transform: translate(0, 100%);
						transform: translate(0, 100%);

						.mg-option {
							margin: 0;
						}

						.mg-group {

							& > a {
								color: #fff;
								padding: 0 5px;
							}

							.mg-toggle {
								color: #fff;
								background: none;
								border: none;
								padding: 2px 2px 2px 10px;
								border-left: 1px solid rgba(255, 255, 255, 0.3);
							}

						}
					}
				}

			}

			.mg-title {
				margin: 13px 0 2px;
				padding-bottom: 2px;
				display: inline-block;
				@include font-size(18);

				small {
					position: relative;
					top: 0;
					left: 0;
					color: #9e9e9e;
					opacity: 0;
					z-index: 1;
					@include font-size(10);
				}

			}

			.mg-description {
				@include clearfix;
			}

			&.thumbnail-selected {
				border-color: #FFF;
				box-shadow: 0 0 8px -1px $color-primary;
				.mg-title {
					small {
						opacity: 1;
					}
				}

				.thumb-preview {

					.mg-thumb-options {
						visibility: visible;

						.mg-toolbar {
							-webkit-transform: translate(0, 0);
							-moz-transform: translate(0, 0);
							-ms-transform: translate(0, 0);
							transform: translate(0, 0);
						}

					}
				}
			}

			// .thumbnail
			&:hover {

				.mg-title {
					small {
						opacity: 1;
					}
				}

				.thumb-preview {

					img {
						-webkit-transform: scale(1.1);
						-moz-transform: scale(1.1);
						-ms-transform: scale(1.1);
						transform: scale(1.1);
					}

					.mg-thumb-options {
						visibility: visible;

						.mg-zoom {
							-webkit-transform: translate(0, 0);
							-moz-transform: translate(0, 0);
							-ms-transform: translate(0, 0);
							transform: translate(0, 0);
						}

						.mg-toolbar {
							-webkit-transform: translate(0, 0);
							-moz-transform: translate(0, 0);
							-ms-transform: translate(0, 0);
							transform: translate(0, 0);
						}

					}
				}
			}

		}
	}

}

// LIGHT SIDEBAR SKIN
// -----------------------------------------------------------------------------
html.sidebar-light:not(.dark) {
	.media-gallery {

		ul.mg-tags {

			& > li {

				a {
					background-color: #f2f2f2;
					color: #777;
				}
			}
		}

	}

}

// DARK SKIN
// -----------------------------------------------------------------------------
html.dark {

	.media-gallery .mg-files .thumbnail {

		&.thumbnail-selected {
			border-color: $dark-color-5;
		}
		.mg-title small {
			color: $dark-default-text;
		}

	}

}