//- モーダルを変更 ------------------------
.modal{
	.modal-content{
		border-radius: 0;
	}
}
//- フォームコントロール ------------------------
.form-control{
	border-radius: 0;
}
//- カレンダーピッカー ------------------------
.calender-picker{
	display: inline-block;
}