// WRAPPER
// -----------------------------------------------------------------------------
/* Sign Screens - Wrappers */
.body-sign {
	display: table;
	height: 100vh;
	margin: 0 auto;
	max-width: 500px;
	padding: 0 15px;
	width: 100%;

	.center-sign {
		display: table-cell;
		padding-top: 20px;
		vertical-align: middle;

		.logo{
			font-size: 26px;
		    font-weight: bold;
		    margin-top: 33px;
		    color: $color-primary;
		}
	}

	.panel-sign {
		background: transparent;

		.panel-title-sign {
			.title {
				background-color: $color-primary;
				border-radius: $border-radius $border-radius 0 0;
				color: #FFF;
				display: inline-block;
				@include font-size(12);
				@include line-height(20);
				padding: 13px 17px;
				vertical-align: bottom;
			}
		}

		.panel-body {
			background: #FFF;
			border-top: 5px solid $color-primary;
			border-radius: $border-radius 0 $border-radius $border-radius;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
			padding: 33px 33px 15px;
		}
	}
}

// ELEMENTS
// -----------------------------------------------------------------------------
/* Sign Screens - Elements */
.body-sign {
	.input-group-icon {
		.input-group-addon i {
			width: 14px;
		}
	}

	.checkbox-custom {
		margin-top: 8px;
	}

	.line-thru {
		display: block;
		@include font-size(12);
		position: relative;

		span {
			color: #CCC;
			position: relative;
			z-index: 3;
		}

		&:before {
			background-color: #FFF;
			content: '';
			height: 10px;
			left: 50%;
			position: absolute;
			margin: -5px 0 0 -20px;
			top: 50%;
			width: 40px;
			z-index: 2;
		}

		&:after {
			border-bottom: 1px solid #DADADA;
			content: '';
			display: block;
			left: 10%;
			position: absolute;
			top: 47%;
			width: 81%;
			z-index: 1;
		}
	}
}

// LOCK SCREEN
// -----------------------------------------------------------------------------
/* Lock Screen */
.body-locked {
	background: url(../images/patterns/noisy_net.png) repeat;
	max-width: none;
	min-height: 400px;

	&.body-locked-inline {
		background: none;
		bottom: 0;
		height: 100%;
		left: 0;
		min-height: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 9999;
	}

	.panel-sign {
		margin: 0 auto;
		max-width: 400px;
		padding-top: 40px;

		.panel-body {
			border-radius: $border-radius;
			position: relative;
		}
	}

	.current-user {
		margin-top: 60px;
		margin-bottom: 35px;

		.user-image {
			border: 5px solid $color-primary;
			border-radius: 150px;
			height: 150px;
			left: 50%;
			position: absolute;
			margin-left: -75px;
			top: -75px;
			width: 150px;
		}

		.user-name {
			@include font-size(30);
			@include line-height(36);
		}

		.user-email {
			@include font-size(11);
			@include line-height(14);
		}
	}
}

/* Locked Screen - Responsive Landscape */
@media only screen and (max-width: 767px) and (orientation : landscape) {
	.body-locked {
		&,
		&.body-locked-inline {
			.panel-sign {
				padding-top: 0;
			}

			.center-sign {
				padding-top: 0;

				.current-user {
					margin-bottom: 45px;
					margin-left: 100px;
					margin-top: 10px;

					.user-image {
						height: 100px;
						left: 35px;
						margin-left: 0;
						margin-top: 0;
						top: 15px;
						width: 100px;
					}
				}
			}
		}
	}
}

/* Lock Screen - Modal */
.mfp-lock-screen {
	&.mfp-bg {
		background: #000 url(../images/patterns/noisy_net.png) repeat;
		opacity: 0.99;
		z-index: 9998;
	}

	&.mfp-wrap {
		background: none;
		z-index: 9999;
	}
}

// DARK SKIN
// -----------------------------------------------------------------------------\
/* dark */
html.dark {
	.body-sign {
		.panel-sign .panel-body {
			background-color: $dark-color-4;
		}

		.line-thru {

			&:before {
				background-color: $dark-color-4;
			}

			&:after {
				border-bottom-color: $dark-color-3;
			}

		}
	}
}