@charset "UTF-8";
/*
@mixin sm()
{
  @media screen and (min-width: 768px) and (max-width: 979px)
  {
    //@content;
  }
}
*/
/*===========================================
=                 Variables                 =
===========================================*/
/*===========================================
=            Include Custom SCSS            =
===========================================*/
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100; }

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200; }

body {
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif; }

/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: Verdana, Meiryo, sans-serif; } }

html, body {
  background-color: #FFF;
  font-feature-settings: "pkna"; }

.btn-big {
  padding: 26px;
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    .btn-big {
      padding: 13px;
      font: 14px; } }

body .btn.btn-radius {
  border-radius: 5px !important; }

.table1 {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc; }
  .table1 td, .table1 th {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px; }
  .table1 th {
    background-color: #F9F9F9; }

body .static {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  padding: 10px;
  background-color: #FFF;
  border: 2px solid red;
  color: red;
  font-weight: bold;
  text-align: center; }
  body .static span {
    display: inline-block;
    position: absolute;
    top: -16px;
    background-color: red;
    padding: 3px;
    line-height: 1;
    left: -2px;
    color: #FFF;
    font-size: 10px;
    font-weight: bold; }

.form_error_message {
  color: #a94442;
  font-size: 10px;
  margin-top: 6px;
  margin-bottom: 0; }

.year_unit {
  padding-left: 0px;
  padding-top: 5px; }

.docment_category_radios {
  margin-top: 7px;
  display: inline-block;
  width: 19%;
  box-sizing: border-box; }

.uploaded_file {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid #ccc; }

.address_check_box_area {
  padding-bottom: 10px; }
  .address_check_box_area label span {
    display: inline-block;
    padding-top: 15px; }

.image-uploader .remove-btn {
  display: none; }

.image-uploader .drop-area,
.image-uploader .image-box {
  display: none; }

.image-uploader .error_message {
  font-size: 11px;
  color: #FFF;
  font-weight: bold; }

.file-list {
  padding: 0;
  margin: 0;
  margin-top: 10px; }
  .file-list li {
    border: 1px solid #eee;
    list-style: none;
    margin: 0;
    padding: 10px;
    position: relative;
    margin-bottom: 10px;
    background: #fff; }
    .file-list li .btn {
      position: absolute;
      top: 8px;
      right: 10px;
      padding: 3px 7px; }
      .file-list li .btn.text {
        font-size: 11px; }
    .file-list li.uploading .btn {
      display: none; }
    .file-list li .error_messsage {
      font-size: 11px;
      color: #D31C1C;
      font-weight: bold; }
    .file-list li.hasError {
      border: 1px solid #D31C1C; }

.note-editor .modal-body {
  padding-left: 30px;
  padding-right: 30px; }

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee; }

.iinkai {
  border: 1px solid #ccc;
  padding: 10px 15px; }
  .iinkai .title {
    margin-bottom: 0;
    padding-bottom: 10px;
    color: #777;
    font-size: 13px; }
  .iinkai .col-md-6.mb-sm {
    overflow: hidden; }

.public_labels .label {
  display: inline-block;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 0.3em;
  padding-bottom: 0.4em; }

.enquete_form {
  margin-bottom: 20px; }
  .enquete_form .enquete_form_add_btn {
    padding: 30px;
    border: 1px dotted #ccc;
    text-align: center;
    background-color: #FFF;
    font-size: 14px;
    color: #ccc; }
    .enquete_form .enquete_form_add_btn:hover {
      background-color: #fffbf8;
      cursor: pointer; }
  .enquete_form .enquete_forms section {
    padding: 30px;
    border: 1px solid #DCDEDD;
    margin-bottom: 20px;
    background-color: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: relative; }
    .enquete_form .enquete_forms section .enquete_remove_btn {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 2px 6px;
      border-radius: 3px; }
    .enquete_form .enquete_forms section .enquete_setting {
      display: none; }

.stepBar {
  position: relative;
  list-style: none;
  margin: 0 0 1em;
  padding: 0;
  text-align: center;
  width: 100%; }
  .stepBar:after {
    content: "";
    display: table;
    clear: both; }
  .stepBar .step {
    position: relative;
    float: left;
    display: inline-block;
    line-height: 40px;
    padding: 0 30px 0 10px;
    background-color: #eee;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .stepBar .step {
        border-radius: 4px;
        margin-bottom: 5px; } }
    .stepBar .step:before, .stepBar .step:after {
      position: absolute;
      left: -15px;
      display: block;
      content: '';
      border-left: 4px solid #FFF;
      width: 20px;
      height: 20px; }
      @media screen and (min-width: 768px) {
        .stepBar .step:before, .stepBar .step:after {
          background-color: #eee; } }
      @media screen and (max-width: 767px) {
        .stepBar .step:before, .stepBar .step:after {
          border-top-color: #eee; } }
      @media screen and (max-width: 767px) {
        .stepBar .step:before, .stepBar .step:after {
          display: none; } }
    @media screen and (min-width: 768px) {
      .stepBar .step:after {
        top: 0;
        -webkit-transform: skew(30deg);
        -moz-transform: skew(30deg);
        -ms-transform: skew(30deg);
        -o-transform: skew(30deg);
        transform: skew(30deg); }
      .stepBar .step:before {
        bottom: 0;
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -ms-transform: skew(-30deg);
        -o-transform: skew(-30deg);
        transform: skew(-30deg); } }
    @media screen and (max-width: 767px) {
      .stepBar .step:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: #eee;
        border-width: 5px;
        margin-left: -5px;
        display: block; } }
    .stepBar .step:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
      .stepBar .step:first-child:before, .stepBar .step:first-child:after {
        content: none; }
        @media screen and (max-width: 767px) {
          .stepBar .step:first-child:before, .stepBar .step:first-child:after {
            content: " "; } }
    .stepBar .step:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
      @media screen and (max-width: 767px) {
        .stepBar .step:last-child:before, .stepBar .step:last-child:after {
          content: none; } }
    @media screen and (min-width: 768px) {
      .stepBar .step.current {
        color: #FFF;
        background-color: #bd004f; }
        .stepBar .step.current:before, .stepBar .step.current:after {
          background-color: #bd004f; } }
    @media screen and (max-width: 767px) {
      .stepBar .step.current {
        color: #FFF;
        background-color: #bd004f; }
        .stepBar .step.current:before, .stepBar .step.current:after {
          border-top-color: #bd004f; } }
  .stepBar.step2 .step {
    width: 50%; }
    @media screen and (max-width: 767px) {
      .stepBar.step2 .step {
        width: 100%; } }
  .stepBar.step3 .step {
    width: 33.333%; }
    @media screen and (max-width: 767px) {
      .stepBar.step3 .step {
        width: 100%; } }
  .stepBar.step4 .step {
    width: 25%; }
    @media screen and (max-width: 767px) {
      .stepBar.step4 .step {
        width: 100%; } }
  .stepBar.step5 .step {
    width: 20%; }
    @media screen and (max-width: 767px) {
      .stepBar.step5 .step {
        width: 100%; } }
  .stepBar.step6 .step {
    width: 16.66666%; }
    @media screen and (max-width: 767px) {
      .stepBar.step6 .step {
        width: 100%; } }

.client_content .item_setting .panel-heading + .panel-body {
  border-radius: 0px; }

.client_content .item_setting .panel-body {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }

.client_content .item_setting .item_form th {
  width: 100px; }

.client_content .item_setting .item_form .code {
  width: 160px; }

.client_content .item_setting .staff_form th {
  width: 100px; }

.client_content .item_setting .staff_form p {
  width: 100%; }

.client_content .item_setting .error_message {
  display: none; }

.client_content .item_setting .item_box {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc; }

.client_content .item_setting .staff_box {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc; }

.client_content #item_delete_modal .modal-dialog {
  width: 700px; }

.client_content #item_edit_modal .modal-dialog {
  width: 700px; }

table .list_right_btn {
  float: right; }

table.list_search_table th {
  width: 160px; }

.search_reset {
  position: absolute;
  top: 11px;
  right: 80px; }

.search_reset_reload {
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 80px;
  display: inline-block; }

.list_area table .new {
  background-color: #01B0F0;
  color: #FFF; }
  .list_area table .new:hover {
    background-color: #009ED7; }

.list_area table .res {
  background-color: #00B050; }
  .list_area table .res:hover {
    background-color: #009744; }

.list_area table .yel {
  background-color: #FEFF00; }
  .list_area table .yel:hover {
    background-color: #E4E500; }

.list_area table .tm {
  background-color: #b879fd;
  color: #FFF; }
  .list_area table .tm:hover {
    background-color: #ab60fd; }

.list_area table .canl {
  background-color: #FF0200;
  color: #FFF; }
  .list_area table .canl:hover {
    background-color: #E50100; }

.list_area .pagination_box {
  text-align: center; }

.hide_item {
  display: none; }

.sort_box {
  float: right; }
  .sort_box .dropdown-menu {
    margin-left: -60px; }

.download_box {
  float: right;
  margin-right: 20px; }
  .download_box .dropdown-menu {
    margin-left: -60px; }

.datepicker {
  z-index: 1020 !important; }

.pdf_dl_box {
  padding: 15px;
  border: 1px solid #ccc;
  background-color: #f7f7f7; }
  .pdf_dl_box h4 {
    text-align: center;
    margin-top: 0;
    border-left: 0 !important; }

.client_login {
  padding-top: 60px; }
  @media screen and (max-width: 767px) {
    .client_login {
      padding-top: 30px; }
      .client_login #pwd-container {
        margin-left: -30px;
        margin-right: -30px; } }
  .client_login .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .client_login .form-control:focus {
      z-index: 2; }
  .client_login form[role=login] {
    color: #5d5d5d;
    background: #f2f2f2;
    padding: 26px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px; }
    @media screen and (max-width: 767px) {
      .client_login form[role=login] {
        padding: 15px; } }
  .client_login form[role=login] img {
    display: block;
    margin: 0 auto;
    margin-bottom: 35px; }
  .client_login form[role=login] input,
  .client_login form[role=login] button {
    font-size: 18px;
    margin: 16px 0; }
    @media screen and (max-width: 767px) {
      .client_login form[role=login] input,
      .client_login form[role=login] button {
        margin-bottom: 0; } }
  .client_login form[role=login] > div {
    text-align: center; }

.client_content.plum_page {
  margin-top: 20px; }
  .client_content.plum_page .panel-body {
    padding: 0 40px; }

.client_content .space {
  border-top: 3px solid #bd004f; }

.client_content .header_cansel_btn {
  font-size: 11px;
  padding: 5px 8px; }

.client_content .client_header {
  padding: 15px 20px;
  border-bottom: 1px solid #EEE;
  font-size: 120%;
  font-weight: bold;
  color: #bd004f;
  line-height: 1;
  background-color: #fff0f6; }
  .client_content .client_header hr {
    display: none; }
  @media screen and (max-width: 767px) {
    .client_content .client_header hr {
      display: block; }
    .client_content .client_header:after {
      content: "";
      display: table;
      clear: both; } }
  .client_content .client_header .back_top {
    margin-top: -9px; }

.client_content.user_content .space {
  border-top: 3px solid #295e84; }

.client_content.user_content .client_header {
  color: #295e84;
  background-color: #e7f1f8; }

.client_content .index_head {
  margin-bottom: 10px; }

.client_content .alertbox {
  margin-top: 0px; }

.client_content .index_buttons {
  margin: 0 -15px; }
  .client_content .index_buttons .index_button_list {
    list-style: none;
    max-width: 1000px;
    padding: 0;
    margin: 40px auto 0; }
    @media screen and (min-width: 768px) {
      .client_content .index_buttons .index_button_list {
        display: -webkit-box;
        display: -moz-box;
        display: box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-lines: multiple;
        -moz-box-lines: multiple;
        box-lines: multiple;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    .client_content .index_buttons .index_button_list li {
      padding: 0 15px; }
      @media screen and (min-width: 768px) {
        .client_content .index_buttons .index_button_list li {
          -webkit-flex-basis: 33.33333333%;
          -moz-flex-basis: 33.33333333%;
          flex-basis: 33.33333333%;
          -ms-flex-preferred-size: 33.33333333%;
          -webkit-flex-basis: calc(100% / 3);
          -moz-flex-basis: calc(100% / 3);
          flex-basis: calc(100% / 3);
          -ms-flex-preferred-size: calc(100% / 3); }
          .client_content .index_buttons .index_button_list li:nth-of-type(-n + 2) {
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            flex-grow: 1;
            -ms-flex-positive: 1; } }
      @media screen and (min-width: 980px) {
        .client_content .index_buttons .index_button_list li {
          -webkit-flex-basis: 25%;
          -moz-flex-basis: 25%;
          flex-basis: 25%;
          -ms-flex-preferred-size: 25%; }
          .client_content .index_buttons .index_button_list li:nth-of-type(-n + 3) {
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            flex-grow: 1;
            -ms-flex-positive: 1; } }
      @media print {
        .client_content .index_buttons .index_button_list li {
          -webkit-flex-basis: 25%;
          -moz-flex-basis: 25%;
          flex-basis: 25%;
          -ms-flex-preferred-size: 25%; }
          .client_content .index_buttons .index_button_list li:nth-of-type(-n + 3) {
            -webkit-flex-grow: 1;
            -moz-flex-grow: 1;
            flex-grow: 1;
            -ms-flex-positive: 1; } }
      .client_content .index_buttons .index_button_list li a {
        display: inline-block;
        width: 100%;
        text-align: center;
        border: 1px solid #bd004f;
        font-size: 115%;
        border-radius: 4px;
        background-color: #ff8abb;
        font-weight: bold;
        padding: 1.75em 0;
        margin-bottom: 20px; }
        .client_content .index_buttons .index_button_list li a .label {
          display: inline-block;
          padding: 4px; }

.client_content .index_btns {
  margin-top: 40px; }
  .client_content .index_btns a.index_btn {
    display: inline-block;
    width: 100%;
    line-height: 500%;
    text-align: center;
    border: 1px solid #bd004f;
    font-size: 115%;
    border-radius: 4px;
    background-color: #ff8abb;
    font-weight: bold; }
  .client_content .index_btns .label-anchor {
    display: inline-block;
    padding: 4px; }

.client_content .error_screen_logo {
  width: 300px; }
  @media screen and (max-width: 767px) {
    .client_content .error_screen_logo {
      width: 100%; } }

.client_content .client_title {
  position: relative;
  padding: .75em 1em .75em 2em;
  border: 1px solid #ccc;
  border-top: 3px solid #bd004f;
  background: -webkit-linear-gradient(top, #fff 0%, #f0f0f0 100%);
  background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
  box-shadow: 0 -1px 0 white inset;
  font-size: 20px;
  font-weight: bold;
  color: #bd004f; }
  .client_content .client_title:after {
    position: absolute;
    top: 1em;
    left: .8em;
    z-index: 2;
    content: '';
    width: 10px;
    height: 10px;
    border: 3px solid #bd004f;
    border-radius: 100%; }
  .client_content .client_title .back_btn {
    margin-top: -5px;
    margin-right: -1px; }

.client_content .notice_box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 15px;
  background-color: #f2dede;
  font-weight: bold; }

.client_content .notice_box2 {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 15px;
  background-color: #e1f2de;
  font-weight: bold; }

.client_content .error_message,
.client_content .simlation_error,
.client_content .error_message_beri {
  background-color: #d2322d;
  padding: 5px 6px;
  margin-top: 7px;
  border-radius: 3px; }
  .client_content .error_message p,
  .client_content .simlation_error p,
  .client_content .error_message_beri p {
    font-size: 11px;
    color: white;
    line-height: 1.4;
    margin-bottom: 0; }

.client_content .error_message_beri {
  display: none; }

.client_content .simlation_error {
  margin-left: 10px;
  margin-right: 10px;
  display: none; }

.client_content .image_error_message {
  background-color: #d2322d;
  padding: 5px 6px;
  margin-top: 7px;
  border-radius: 3px;
  color: #FFF; }

.client_content .upload_error {
  display: none; }

.client_content .message {
  border: 1px solid #006f90;
  background-color: #FFF;
  padding: 5px 6px;
  margin-bottom: 7px;
  border-radius: 3px; }
  .client_content .message p {
    font-size: 12px;
    color: #006f90;
    line-height: 1.4;
    margin-bottom: 0; }

.client_content .form_area {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  margin-bottom: 20px; }
  .client_content .form_area.confirm {
    padding: 10px;
    border: 2px solid #5bc0de;
    background-color: #fff;
    box-shadow: 0px 0px 4px -1px #000000; }
  .client_content .form_area.detail {
    padding: 10px;
    border: 2px solid #bd004f;
    background-color: #fff;
    box-shadow: 0px 0px 4px -1px #000000; }
  .client_content .form_area.check {
    padding: 10px;
    border: 2px solid #666;
    background-color: #fff;
    box-shadow: 0px 0px 4px -1px #000000; }
  .client_content .form_area.account {
    padding: 10px;
    border: 2px solid red;
    background-color: #f2dede;
    box-shadow: 0px 0px 4px -1px #000000;
    margin: 10px; }
    @media screen and (max-width: 767px) {
      .client_content .form_area.account {
        margin: 0;
        margin-top: 10px; }
        .client_content .form_area.account .btn-lg {
          font-size: 14px;
          padding: 10px; }
        .client_content .form_area.account .btn-sm {
          font-size: 10px; } }
    .client_content .form_area.account h5 {
      text-align: center;
      color: red;
      font-size: 16px;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .client_content .form_area.account h5 {
          margin-top: 0;
          font-size: 12px; } }
  .client_content .form_area.user-confirm {
    padding: 10px;
    border: 2px solid #ed9c28;
    background-color: #fff;
    box-shadow: 0px 0px 4px -1px #000000; }
  .client_content .form_area.wait_message_box {
    padding: 10px;
    border: 2px solid #bd004f;
    background-color: #FFF;
    box-shadow: 0px 0px 4px -1px #000000;
    margin: 10px; }
    @media screen and (max-width: 767px) {
      .client_content .form_area.wait_message_box {
        margin: 0;
        margin-top: 10px; }
        .client_content .form_area.wait_message_box .btn-lg {
          font-size: 14px;
          padding: 10px; } }
    .client_content .form_area.wait_message_box h5 {
      font-size: 20px;
      text-align: center;
      color: #bd004f;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .client_content .form_area.wait_message_box h5 {
          margin-top: 0;
          font-size: 16px; } }
    .client_content .form_area.wait_message_box.confirm_crepico {
      background-color: #e7f8f4;
      border-style: double;
      border-width: 5px;
      line-height: 1.25; }

.client_content .form_table {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Safari */
  margin-top: 10px; }
  .client_content .form_table:first-child {
    margin-top: 0; }
  .client_content .form_table th, .client_content .form_table td {
    padding: 10px;
    border: 1px solid #ff8abb;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* Safari */ }
    @media screen and (max-width: 767px) {
      .client_content .form_table th, .client_content .form_table td {
        display: block;
        width: 100% !important; } }
  .client_content .form_table tr {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* Safari */ }
  .client_content .form_table th {
    width: 175px;
    border-left: 0;
    color: #bd004f;
    position: relative; }
    @media screen and (max-width: 767px) {
      .client_content .form_table th {
        background-color: #fff0f6;
        border-left: 1px solid #ff8abb; } }
    .client_content .form_table th .hissu {
      font-size: 10px;
      color: white;
      position: absolute;
      background-color: #d2322d;
      padding: 4px;
      line-height: 1;
      right: 5px;
      top: 5px;
      margin-top: 0px; }
  .client_content .form_table.form_table_sm th {
    width: 140px; }
  .client_content .form_table td {
    border-right: 0; }
    @media screen and (max-width: 767px) {
      .client_content .form_table td {
        margin-bottom: 10px;
        border-right: 1px solid #ff8abb;
        border-top: 0; } }
    .client_content .form_table td .error_message {
      display: none; }
  .client_content .form_table tr:nth-child(odd) {
    background-color: #FFF; }
    @media screen and (max-width: 767px) {
      .client_content .form_table tr:nth-child(odd) {
        background-color: #FFF; } }
  .client_content .form_table tr:nth-child(even) {
    background-color: #fff0f6; }
    @media screen and (max-width: 767px) {
      .client_content .form_table tr:nth-child(even) {
        background-color: #FFF; } }
  .client_content .form_table tr.odd {
    background-color: #FFF; }
  .client_content .form_table tr.even {
    background-color: #fff0f6; }
  .client_content .form_table tr.error {
    border: 2px solid #d2322d; }
  .client_content .form_table .form-span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }
  .client_content .form_table .form-unit {
    display: inline-block;
    padding-left: 5px;
    padding-right: 10px; }
    @media screen and (max-width: 767px) {
      .client_content .form_table .form-unit {
        padding-right: 5px;
        padding-left: 3px; } }
  .client_content .form_table .form-span-before {
    display: inline-block;
    padding-left: 0px;
    padding-right: 10px; }
  .client_content .form_table .form-span-after {
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px; }
  .client_content .form_table .sale_item_tr .item_empty_btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: -12px; }
  .client_content .form_table .items .item_flex {
    display: flex; }
  .client_content .form_table .items .item_category {
    margin-bottom: 5px;
    font-size: 11px; }
  .client_content .form_table .items .item_btn {
    width: 100px; }
    .client_content .form_table .items .item_btn a {
      display: block; }
  .client_content .form_table .items .item_name {
    width: 100%;
    padding-left: 1px;
    padding-right: 10px;
    flex: 1; }
  .client_content .form_table .items .item_amount {
    width: 60px;
    padding-right: 5px; }
  .client_content .form_table .items .item_amount_unit {
    display: inline-block;
    padding-right: 5px;
    padding-top: 5px; }
  .client_content .form_table .items .item_price {
    width: 120px;
    padding-right: 5px; }
  .client_content .form_table .items .item_price_unit {
    display: inline-block;
    padding-top: 5px; }
  .client_content .form_table #birthday_view {
    margin-left: 20px; }
    @media screen and (max-width: 767px) {
      .client_content .form_table #birthday_view {
        margin-left: 5px; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table .tel-form .sample {
      display: inline-block;
      margin-top: 5px; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table .partner_radio {
      font-size: 11px;
      padding: 6px 6px;
      width: auto; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table #live_people_count select {
      width: 80px;
      display: inline-block; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table #other_maintainer {
      margin-top: 10px; } }
  @media screen and (min-width: 768px) {
    .client_content .form_table #now_work-1 + label br,
    .client_content .form_table #now_work-2 + label br,
    .client_content .form_table #now_work-3 + label br {
      display: none; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table #now_work-1 + label br,
    .client_content .form_table #now_work-2 + label br,
    .client_content .form_table #now_work-3 + label br {
      display: inline-block; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table #now_work-1 + label,
    .client_content .form_table #now_work-2 + label,
    .client_content .form_table #now_work-3 + label {
      font-size: 11px;
      padding: 6px 5px;
      /*
				font-size: 10px;
				padding:6px 0px;
				*/ } }
  @media screen and (max-width: 767px) {
    .client_content .form_table #beri_type .w100 {
      width: 80px; } }
  @media screen and (max-width: 767px) {
    .client_content .form_table .hope_date .show-calender-btn {
      margin-top: -2px; }
    .client_content .form_table .hope_date .select-date {
      width: 200px; }
    .client_content .form_table .hope_date .select-hour1 {
      width: 80px;
      display: inline-block; }
    .client_content .form_table .hope_date .select-minute1 {
      width: 80px;
      display: inline-block; }
    .client_content .form_table .hope_date .select-hour2 {
      margin-top: 5px;
      width: 80px;
      display: inline-block; }
    .client_content .form_table .hope_date .select-minute2 {
      margin-top: 5px;
      width: 80px;
      display: inline-block; } }

.client_content .form-file {
  margin-top: 10px; }
  .client_content .form-file .file-empty {
    padding: 40px;
    background-color: #efefef;
    border: 2px dotted #9a9a9a;
    text-align: center;
    font-size: 150%;
    font-weight: bold;
    color: #ccc;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .client_content .form-file .file-empty {
        padding: 20px 10px;
        font-size: 12px; } }
  .client_content .form-file .file-uploaded {
    border: 1px solid #ccc;
    text-align: center;
    padding: 10px;
    background-color: #FFF; }
    .client_content .form-file .file-uploaded img {
      height: 200px; }
      @media screen and (max-width: 767px) {
        .client_content .form-file .file-uploaded img {
          width: 100%;
          height: auto; } }
    .client_content .form-file .file-uploaded.file-uploaded_confirm img {
      height: auto;
      max-width: 100%; }

.client_content .form-btns {
  display: inline-block;
  margin: 0;
  padding: 0;
  clear: both;
  vertical-align: bottom; }
  .client_content .form-btns li {
    display: inline-block;
    position: relative;
    float: left;
    list-style: none;
    border: solid 1px #ddd;
    border-right: none;
    overflow: hidden; }
  .client_content .form-btns li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .client_content .form-btns li:last-child {
    border-right: solid 1px #ddd;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .client_content .form-btns label {
    display: block;
    min-width: 3em;
    text-align: center;
    background: #eee;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
    border: solid 1px rgba(255, 255, 255, 0.2);
    padding: 8px 10px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    margin-bottom: 0; }
  .client_content .form-btns li:first-child label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .client_content .form-btns li:last-child label {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .client_content .form-btns input[type=radio] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer; }
  .client_content .form-btns input[type=radio]:disabled {
    cursor: not-allowed; }
  .client_content .form-btns input[type=radio]:checked {
    cursor: auto; }
  .client_content .form-btns input[type=radio]:checked + label {
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background: #666;
    background: -webkit-gradient(linear, left top, left bottom, from(#313131), to(#626262)); }
  .client_content .form-btns input[type=radio]:not(:checked):disabled + label,
  .client_content .form-btns input[type=checkbox]:not(:checked):disabled + label {
    color: #888;
    background: #ddd;
    background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ddd)); }
  .client_content .form-btns input[type=checkbox] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer; }
  .client_content .form-btns input[type=checkbox]:checked {
    cursor: auto; }
  .client_content .form-btns input[type=checkbox]:checked + label {
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background: #666;
    background: -webkit-gradient(linear, left top, left bottom, from(#313131), to(#626262)); }
  .client_content .form-btns.btn_wid100 label {
    width: 100px; }
  .client_content .form-btns.btn_wid80 label {
    width: 80px; }

.client_content .form-dl {
  margin-bottom: 7px;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .client_content .form-dl {
      display: block; } }
  .client_content .form-dl dt {
    width: 100px;
    padding-right: 10px;
    text-align: right; }
    @media screen and (max-width: 767px) {
      .client_content .form-dl dt {
        width: 100%;
        text-align: left;
        font-size: 11px; } }
  .client_content .form-dl dd {
    flex: 1; }
    @media screen and (max-width: 767px) {
      .client_content .form-dl dd {
        width: 100%;
        text-align: left; } }

.client_content .form-address {
  margin-bottom: 7px; }

.client_content .total_view {
  border: 2px solid #bd004f;
  margin: 20px 10px;
  border-radius: 5px; }
  @media screen and (max-width: 767px) {
    .client_content .total_view {
      margin: 10px 0px;
      border-radius: 4px; } }
  .client_content .total_view h3:first-child {
    margin-top: 10px; }

.client_content .pdf_box h3 {
  margin-top: 0; }

.client_content .mail_box h3 {
  margin-top: 0; }

.client_content .mail_box .mail_sended_list > div:not(:last-of-type) {
  opacity: .4; }

.client_content .mail_box input.mail {
  width: 290px;
  display: inline-block;
  vertical-align: top; }

.client_content .mail_box input.name {
  width: 80px;
  display: inline-block;
  vertical-align: top; }

.client_content .mail_box a {
  vertical-align: top; }

.client_content .mail_box .mail_form span {
  display: inline-block;
  margin-top: 5px; }

.client_content .sms_box #send_sms_btn.disabled::after {
  content: '中...'; }

.client_content .submit_btn_area {
  padding-top: 20px;
  padding-bottom: 20px; }

.client_content .coolingoff h3 span {
  color: red; }

.client_content .coolingoff .text {
  color: red; }

.client_content .text_wrap {
  max-width: 760px;
  margin: 0 auto;
  /* components */ }
  .client_content .text_wrap.text_box {
    padding: 15px 13px;
    border: 3px solid #298470;
    border-radius: 4px; }
  .client_content .text_wrap .cmpt_title {
    background-color: #a32d30;
    color: white;
    font-weight: bold;
    padding: 2px 6px;
    border: none;
    margin: 0 0 10px; }
    .client_content .text_wrap .cmpt_title + section > .cmpt_subtitle:first-child {
      margin-top: -10px; }
  .client_content .text_wrap .cmpt_subtitle {
    background-color: #f7dbdb;
    font-weight: normal;
    padding: 2px 6px;
    border: none;
    margin: 0 0 10px; }
  .client_content .text_wrap [class*="cmpt-text"] ol, .client_content .text_wrap [class*="cmpt-text"] ul {
    padding-left: 1.5em; }
    .client_content .text_wrap [class*="cmpt-text"] ol li, .client_content .text_wrap [class*="cmpt-text"] ul li {
      margin-bottom: 1em; }
  .client_content .text_wrap [class*="cmpt-text"] ol.parenthesized {
    list-style: none;
    padding-left: 2em;
    counter-reset: li 0; }
    .client_content .text_wrap [class*="cmpt-text"] ol.parenthesized > li {
      counter-increment: li; }
      .client_content .text_wrap [class*="cmpt-text"] ol.parenthesized > li > p:first-child::before {
        content: "（" counter(li) "）";
        display: inline-block;
        text-align: center;
        margin-left: -2.5em;
        width: 2.5em; }
      .client_content .text_wrap [class*="cmpt-text"] ol.parenthesized > li > p[data-marker]:first-child::before {
        content: "（" attr(data-marker) "）"; }
  .client_content .text_wrap [class*="cmpt-text"] .clause dt {
    margin-bottom: 10px; }
  .client_content .text_wrap [class*="cmpt-text"] .clause ul {
    list-style: none;
    padding-left: 1em; }
  .client_content .text_wrap [class*="cmpt-text"] .contact dt {
    font-weight: normal;
    margin-bottom: 0; }
  .client_content .text_wrap [class*="cmpt-text"] .contact dd {
    padding-left: 1.5em; }
  .client_content .text_wrap [class*="cmpt-text"] .border_danger {
    border: 2px solid #d2322d;
    padding: 2px 5px;
    margin-bottom: 12px; }
    .client_content .text_wrap [class*="cmpt-text"] .border_danger.dotted {
      border-style: dotted; }
    .client_content .text_wrap [class*="cmpt-text"] .border_danger > .cmpt_title {
      margin: 0 -3px 10px; }
  .client_content .text_wrap [class*="cmpt-text"] .title_danger {
    border: 2px solid #d2322d;
    color: #d2322d;
    line-height: 1.25;
    padding: 0 3px; }
  .client_content .text_wrap .cmpt-text3 table {
    margin-bottom: 1em; }
  .client_content .text_wrap .cmpt-text3 .annotation dt {
    font-weight: normal; }
  .client_content .text_wrap .cmpt-text6 .underline_title {
    text-decoration: underline;
    font-size: 1.25em; }
  .client_content .text_wrap .cmpt-text6 ul {
    list-style-type: disc;
    margin-bottom: 1em; }

.client_content .ti-1 {
  text-indent: 1em;
  margin-bottom: 10px; }
  .client_content .ti-1 + .ti-1 {
    margin-top: -10px; }

.client_content .mb-0 {
  margin-bottom: 0; }

.client_content .digital_contract h3 {
  background: none;
  border: none;
  font: 18px; }

.client_content .digital_contract .agree_btn_area {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px; }
  .client_content .digital_contract .agree_btn_area li {
    list-style: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    display: inline-block;
    width: 180px;
    margin: 0 20px; }
    .client_content .digital_contract .agree_btn_area li span {
      display: block;
      margin-top: 5px;
      font-size: 11px;
      color: #666; }
    .client_content .digital_contract .agree_btn_area li a {
      display: block;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      color: #333; }
    .client_content .digital_contract .agree_btn_area li.cancel a {
      border: 1px solid #ccc;
      background-color: #e5e5e5;
      padding: 10px;
      border-radius: 4px; }
      .client_content .digital_contract .agree_btn_area li.cancel a:hover {
        opacity: 0.7; }
    .client_content .digital_contract .agree_btn_area li.agree a {
      border: 1px solid #ccc;
      background-color: #e5e5e5;
      padding: 10px;
      border-radius: 4px; }
      .client_content .digital_contract .agree_btn_area li.agree a:hover {
        opacity: 0.7; }
      .client_content .digital_contract .agree_btn_area li.agree a .icon_check {
        display: none; }
      .client_content .digital_contract .agree_btn_area li.agree a .icon_no_check {
        display: inline-block; }
    .client_content .digital_contract .agree_btn_area li.agree.checked a {
      border: 1px solid #bd004f;
      background-color: #ff8abb;
      padding: 10px;
      border-radius: 4px;
      color: #bd004f; }
      .client_content .digital_contract .agree_btn_area li.agree.checked a .icon_check {
        display: inline-block; }
      .client_content .digital_contract .agree_btn_area li.agree.checked a .icon_no_check {
        display: none; }

.client_content .digital_contract .digital_block {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px; }
  .client_content .digital_contract .digital_block h4 {
    font-size: 16px; }
  .client_content .digital_contract .digital_block p {
    padding-left: 15px; }

.client_content .text-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  padding: 10px;
  border: 1px solid #bd004f;
  height: 320px; }

.client_content .text-scroll2 {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  padding: 10px;
  border: 1px solid #bd004f;
  height: 480px; }

.client_content .check_area {
  padding-top: 15px; }
  .client_content .check_area .checkbox-custom {
    display: inline-block; }

.client_content #item_list_modal .item_list, .client_content #item_list_modal .item_list_category {
  padding: 0;
  margin: 0; }
  .client_content #item_list_modal .item_list li, .client_content #item_list_modal .item_list_category li {
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    padding: 10px;
    list-style: none;
    background-color: #f7f7f7;
    margin-bottom: 5px; }
    .client_content #item_list_modal .item_list li:last-child, .client_content #item_list_modal .item_list_category li:last-child {
      margin-bottom: 0; }
    .client_content #item_list_modal .item_list li table, .client_content #item_list_modal .item_list_category li table {
      width: 100%; }
      .client_content #item_list_modal .item_list li table .name, .client_content #item_list_modal .item_list_category li table .name {
        width: 58%; }
      .client_content #item_list_modal .item_list li table .month, .client_content #item_list_modal .item_list_category li table .month {
        width: 12%; }
      .client_content #item_list_modal .item_list li table .price, .client_content #item_list_modal .item_list_category li table .price {
        width: 15%; }
      .client_content #item_list_modal .item_list li table .button, .client_content #item_list_modal .item_list_category li table .button {
        width: 15%; }

.client_content #item_list_modal .item_list_category {
  margin-top: 10px; }

.client_content .search_area {
  margin-top: 20px; }
  .client_content .search_area .panel {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee; }
    .client_content .search_area .panel th {
      background-color: #e7f8f4;
      width: 160px; }

.client_content .calender-picker select {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px; }

.client_content .agree_area_btns ul {
  text-align: center; }
  .client_content .agree_area_btns ul li {
    display: inline-block;
    width: 240px;
    padding-left: 20px; }
    .client_content .agree_area_btns ul li a {
      font-size: 18px;
      font-weight: bold; }

.client_content .change_password_box {
  width: 760px;
  margin: 0 auto;
  padding-top: 40px; }

.client_content .individual_wrap .panel-body {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }
  .client_content .individual_wrap .panel-body th {
    background-color: #e7f8f4;
    font-size: 12px;
    width: 130px;
    padding: 5px; }
  .client_content .individual_wrap .panel-body td {
    font-size: 12px;
    padding: 5px; }

.client_content .individual_wrap .btn-vsm {
  font-size: 11px;
  padding: 3px;
  line-height: 1;
  margin-top: 6px; }

.client_content .individual_wrap #tel_change_modal .form-dl {
  margin-bottom: 10px; }
  .client_content .individual_wrap #tel_change_modal .form-dl dt {
    margin-bottom: 5px; }

.client_content .individual_wrap #tel_change_modal .form-dl_last {
  margin-bottom: 0px; }

.client_content .individual_wrap #tel_change_modal .error_message p {
  margin-bottom: 0;
  color: red;
  font-size: 11px;
  margin-top: 10px; }

.client_content #sign_area {
  margin-top: 20px;
  margin-bottom: 20px; }
  .client_content #sign_area .sign_box {
    width: 500px;
    margin: 0 auto; }
  .client_content #sign_area .sign_border {
    height: 200px;
    border: 1px solid #ccc; }
    .client_content #sign_area .sign_border canvas {
      width: 500px;
      height: 200px; }
  .client_content #sign_area .sign_btns {
    margin-top: 10px; }

.client_content .select_bank {
  padding: 40px; }
  .client_content .select_bank ul {
    margin: 0;
    padding: 0;
    border: 5px solid #ccc; }

.client_content h3 {
  border-top: 1px solid #bd004f;
  border-bottom: 1px solid #bd004f;
  padding: 5px;
  padding-left: 4px;
  font-size: 120%;
  color: #bd004f;
  font-weight: bold;
  background-color: #fff0f6; }
  .client_content h3 span {
    border-left: 3px solid #bd004f;
    display: inline-block;
    padding: 2px;
    padding-left: 6px; }
  .client_content h3.red_title {
    background-color: red;
    color: white;
    border-top: 1px solid red;
    border-bottom: 1px solid red; }
    .client_content h3.red_title span {
      border-left: 3px solid white; }

.client_content h4 {
  font-weight: bold;
  border-left: 3px solid #bd004f;
  font-size: 14px;
  padding: 2px;
  padding-left: 6px;
  margin: 0; }
  .client_content h4.red_title {
    color: red;
    border-left: none;
    border: 2px solid red;
    margin-bottom: 10px; }

.client_content h5.red_title {
  color: red; }

.client_content h5.bold {
  font-weight: bold; }

.client_content .indent_1 {
  text-indent: -1em;
  margin-left: 1em; }

.client_content .indent_2 {
  text-indent: -1em;
  margin-left: 1em; }

.client_content .indent_3 {
  text-indent: -1em;
  margin-left: 2em; }

.client_content .color_red {
  color: red; }

.client_content .tal-c {
  text-align: center; }

.client_content .tal-r {
  text-align: right; }

.client_content .tal-l {
  text-align: left; }

.client_content .form-ib {
  display: inline-block; }

.client_content .fwb {
  font-weight: bold; }

.client_content .w320 {
  width: 320px; }

.client_content .w250 {
  width: 250px; }

.client_content .w200 {
  width: 200px; }

.client_content .w120 {
  width: 120px; }
  @media screen and (max-width: 767px) {
    .client_content .w120 {
      width: 85px; } }

.client_content .w100 {
  width: 100px; }

.client_content .w80 {
  width: 80px; }
  @media screen and (max-width: 767px) {
    .client_content .w80 {
      width: 65px; } }

.client_content .w60 {
  width: 60px; }

.client_content .w50 {
  width: 50px; }

.client_content .w40 {
  width: 40px; }

.client_content .h120 {
  height: 120px; }

@media screen and (max-width: 767px) {
  .client_content .select-year {
    width: 85px; } }

@media screen and (max-width: 767px) {
  .client_content .select-month {
    width: 65px; } }

@media screen and (max-width: 767px) {
  .client_content .select-day {
    width: 65px; } }

.client_content #mailAddressArea input, .client_content #mailAddressArea select,
.client_content .mail_form input,
.client_content .mail_form select {
  width: 160px;
  text-transform: lowercase; }

.client_content #mailAddressArea .form-ib,
.client_content .mail_form .form-ib {
  vertical-align: text-top; }

.client_content #mailAddressArea .mail_address_at,
.client_content .mail_form .mail_address_at {
  padding: 6px 0; }

.client_content #mailAddressArea .show_other,
.client_content .mail_form .show_other {
  width: 6em;
  padding: 6px; }

.client_content .mail_box .mail_form .mail_address_input {
  display: table; }
  .client_content .mail_box .mail_form .mail_address_input dt, .client_content .mail_box .mail_form .mail_address_input dd {
    display: table-cell;
    font-weight: normal; }
  .client_content .mail_box .mail_form .mail_address_input .mail_address_label {
    width: 110px;
    text-align: right;
    margin: 0 .25em 0 0; }
  .client_content .mail_box .mail_form .mail_address_input .mail_address_at {
    padding: 8px 0;
    margin-top: 0; }
  .client_content .mail_box .mail_form .mail_address_input #send_mail_btn {
    vertical-align: text-top; }

.client_content .information_area .area_header,
.client_content .manual_area .area_header {
  border-bottom: solid 1px #eee;
  margin-bottom: 20px; }
  .client_content .information_area .area_header h2,
  .client_content .manual_area .area_header h2 {
    font-size: 24px;
    font-weight: bold;
    color: #bd004f;
    margin-top: 0; }

.client_content .information_area .info_header {
  padding: 10px 15px; }
  .client_content .information_area .info_header .info_title .info_vc_title {
    word-break: break-all; }

.client_content .information_area .info_date {
  color: #aaa;
  font-size: 12px; }

.client_content .information_area .info_title {
  font-size: 18px; }

.client_content .information_area .info_text {
  white-space: pre-line;
  margin-bottom: 0; }

.client_content .manual_area .manual_list {
  padding-left: 0;
  list-style: none; }
  .client_content .manual_area .manual_list li + li {
    margin-top: 20px; }
  .client_content .manual_area .manual_list .manual_box {
    display: block;
    text-decoration: none;
    color: #222;
    transition: border-color .125s;
    margin: 0; }
    .client_content .manual_area .manual_list .manual_box:hover {
      border-color: #bd004f; }
      .client_content .manual_area .manual_list .manual_box:hover .manual_icon {
        color: #c15d44; }
      .client_content .manual_area .manual_list .manual_box:hover .manual_name {
        color: #bd004f; }
    .client_content .manual_area .manual_list .manual_box:not(:hover) {
      border-color: #ddd; }
    .client_content .manual_area .manual_list .manual_box .manual_icon {
      font-size: 32px;
      margin-top: 10px;
      transition: color .125s; }
    .client_content .manual_area .manual_list .manual_box .manual_name {
      font-weight: bold;
      word-break: break-all;
      transition: color .125s; }
    .client_content .manual_area .manual_list .manual_box .manual_size {
      font-size: 12px;
      color: #888; }
    .client_content .manual_area .manual_list .manual_box .manual_time {
      font-size: 12px; }

.client_content .manual_area .qa_container {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 15px; }
  @media screen and (min-width: 768px) {
    .client_content .manual_area .qa_container {
      margin-left: -15px; } }
  .client_content .manual_area .qa_container .qa_list dt, .client_content .manual_area .qa_container .qa_list dd {
    display: table;
    width: 100%;
    padding: 6px; }
    .client_content .manual_area .qa_container .qa_list dt > span, .client_content .manual_area .qa_container .qa_list dd > span {
      display: table-cell; }
    .client_content .manual_area .qa_container .qa_list dt .faq_no, .client_content .manual_area .qa_container .qa_list dd .faq_no {
      font-weight: normal;
      width: 1px;
      padding-right: .75em;
      white-space: nowrap;
      font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
      font-size: 14px; }
  .client_content .manual_area .qa_container .qa_list dt {
    counter-increment: qa;
    background-color: #fff0f6;
    color: #bd004f;
    border-radius: 4px;
    margin-top: 15px; }
  .client_content .manual_area .qa_container .qa_list dd .faq_no {
    color: #be3b3b; }
  .client_content .manual_area .qa_container .qa_list dd .answer {
    white-space: pre-line; }

.client_content .manual_area .storedfile_list {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .client_content .manual_area .storedfile_list .manual_box {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -moz-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
    .client_content .manual_area .storedfile_list .manual_box:hover .manual_icon {
      color: #bd004f; }
  .client_content .manual_area .storedfile_list li {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px; }
    .client_content .manual_area .storedfile_list li + li {
      margin-top: 0; }
    .client_content .manual_area .storedfile_list li .manual_box {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }

.client_content .pe-none {
  pointer-events: none; }

.client_content .emphasis_left {
  font-size: 14px;
  font-weight: bold;
  border-color: #999 #ddd #999 #999;
  border-style: solid;
  border-width: 2px 1px 2px 2px; }

.client_content .emphasis_right {
  font-size: 14px;
  font-weight: bold;
  border-color: #999 #999 #999 #ddd;
  border-style: solid;
  border-width: 2px 2px 2px 1px; }

.modal .modal-body .zip_list {
  min-height: 50px; }

.client_content.modal-open .datepicker {
  z-index: 1060 !important; }

#set_cancel_modal .modal-content {
  border: 3px solid #d2322d; }
  #set_cancel_modal .modal-content .modal-title {
    color: #d2322d;
    border-left: #d2322d; }

#tel_reset_modal .beri_modal_title {
  margin-bottom: 9px;
  padding-bottom: 4px;
  border-bottom: 1px solid #666;
  font-weight: bold; }

#tel_reset_modal .error_message {
  background-color: #FFF;
  padding: 0;
  margin: 0; }
  #tel_reset_modal .error_message p {
    color: red;
    margin-bottom: 10px; }

/* テスト環境 */
body.client_content.staging .client_login form [type="submit"] {
  background-color: #575757;
  border-color: #575757 #575757 #3D3D3D; }

body.client_content.staging h4 {
  border-color: #575757; }

body.client_content.staging .client_title {
  color: #575757; }
  body.client_content.staging .client_title, body.client_content.staging .client_title::after {
    border-color: #575757; }

body.client_content.staging .search_area .panel th {
  background-color: #f0f0f0; }

body.client_content.staging .individual_wrap .panel-body th {
  background-color: #f0f0f0; }

body.client_content.staging .space {
  border-top-color: #000; }
  body.client_content.staging .space .client_header {
    color: #FFF;
    background-color: #3C3C3C; }

body.client_content.staging .panel-primary > .panel-heading {
  background-color: #575757;
  border-bottom-color: #757575; }

body.client_content.staging .form_area.confirm {
  border-color: #88a6af; }

body.client_content.staging .form_area.user-confirm {
  border-color: #a18e72; }

.chat_area {
  margin-left: -15px;
  margin-right: -15px; }
  .chat_area.plum_area {
    margin-left: 0;
    margin-right: 0; }
    .chat_area.plum_area .chat_box {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch; }
  .chat_area .menu_list {
    height: 100%;
    border-right: 1px solid #ccc;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .chat_area .menu_list ul {
      padding: 0; }
    .chat_area .menu_list li {
      cursor: pointer;
      list-style: none;
      padding-left: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding: 10px;
      border-bottom: 1px solid #CCC; }
    .chat_area .menu_list .id {
      font-size: 11px;
      font-weight: bold; }
    .chat_area .menu_list .name {
      font-size: 20px;
      font-weight: bold;
      display: block;
      margin-top: 5px; }
    .chat_area .menu_list .kana {
      font-size: 12px;
      font-weight: bold;
      display: block;
      color: #666; }
    .chat_area .menu_list .notice {
      background-color: #FFDEDE; }
    .chat_area .menu_list .cur {
      background-color: #FAFFDE; }
  .chat_area .chat_box {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .chat_area .chat_box .detail {
      margin: 10;
      border: 2px solid #bd004f;
      padding: 20px; }
      .chat_area .chat_box .detail .table1 .two th {
        width: 17%; }
      .chat_area .chat_box .detail .table1 .two td {
        width: 33%; }
    .chat_area .chat_box .input_form {
      padding: 10px;
      border: 1px solid #CCC; }
    .chat_area .chat_box .message_list .message_item {
      margin-bottom: 10px;
      border: 1px solid #666;
      padding: 10px;
      border-radius: 4px; }
      .chat_area .chat_box .message_list .message_item.plum {
        background-color: #e8fffa; }
      .chat_area .chat_box .message_list .message_item .message_line {
        margin-top: 0;
        border-bottom: 1px solid #ccc; }

.redinvoice_wrap {
  padding: 0 20px; }

.red_invoice_title {
  font-size: 24px;
  font-weight: bold; }

.red_invoice_form {
  margin-top: 15px;
  border: 2px solid #efefef;
  border-radius: 4px;
  background-color: #f0f0f0;
  padding: 20px; }
  .red_invoice_form hr {
    border-bottom: 1px solid #bababa; }
  .red_invoice_form label {
    font-weight: bold; }
  .red_invoice_form .search_form_two .form-control {
    padding: 2px 6px; }
  .red_invoice_form .number_form, .red_invoice_form .number2_form, .red_invoice_form .name_form {
    margin-right: 10px; }
  .red_invoice_form .number_form .form-control {
    width: 100px; }
  .red_invoice_form .number2_form .form-control {
    width: 40px; }
  .red_invoice_form .name_form .form-control {
    width: 120px; }
  .red_invoice_form .birthday_form .form-control {
    width: 100px; }
  .red_invoice_form .search_form_two {
    margin-top: 10px; }
  .red_invoice_form .red_invoice_error_message {
    display: none;
    color: white;
    font-size: 11px;
    padding: 2px 5px; }

.redinvoice_loading {
  display: none; }
  .redinvoice_loading .loading_box {
    height: 100px; }

.redinvoice_detail .invoice_message p {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: bold; }

.redinvoice_error .invoice_message {
  border: 2px solid red;
  background-color: #fde8e8; }
  .redinvoice_error .invoice_message p {
    font-size: 22px;
    color: red;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    text-align: center; }

.redinvoice_detail .table-red_invoice-data th {
  background-color: #f0f0f0; }

.redinvoice_detail .table-red_invoice-data .return_area .form-btns {
  vertical-align: top; }

.redinvoice_detail .table-red_invoice-data .return_box {
  display: inline-block;
  width: 130px;
  vertical-align: top;
  margin-top: 10px;
  display: none; }
  .redinvoice_detail .table-red_invoice-data .return_box span {
    display: inline-block;
    font-size: 11px;
    font-weight: bold; }

.redinvoice_detail .table-red_invoice-data.agency_agree_table {
  display: none; }

.redinvoice_detail .table-red_invoice-data .client_mail_check {
  display: none;
  padding: 10px;
  margin-top: 10px;
  background-color: #efefef;
  border: 1px solid #ccc; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check .form-ib, .redinvoice_detail .table-red_invoice-data .client_mail_check select {
    display: inline-block;
    vertical-align: text-top;
    text-transform: lowercase; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check select {
    width: 160px; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check input.form-ib {
    width: 160px; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check .mail_address_at {
    padding: 6px 0; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check .other_domain {
    display: none; }
  .redinvoice_detail .table-red_invoice-data .client_mail_check.show {
    display: block; }

.redinvoice_detail .table-red_invoice-data .shop_check {
  display: none;
  padding: 10px;
  margin-top: 10px;
  background-color: #efefef;
  border: 1px solid #ccc; }
  .redinvoice_detail .table-red_invoice-data .shop_check .agency_shop_agree_box {
    text-align: center; }
  .redinvoice_detail .table-red_invoice-data .shop_check .agency_shop_agree_custom {
    display: inline-block; }

.redinvoice_detail .table-red_invoice-data .commentMessage {
  font-size: 16px;
  font-weight: bold;
  color: red; }

.redinvoice_detail .table-red_invoice-data .date {
  display: block; }

.redinvoice_detail .table-red_invoice-data .agency_upload_date {
  padding-top: 5px;
  font-weight: bold; }

.redinvoice_detail .table-red_invoice-data .readed_check_date {
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  font-size: 11px; }

.redinvoice_detail .table-red_invoice-detail th {
  width: 160px; }

.redinvoice_detail .seisan_select .raduo-custom {
  display: inline-block; }

.redinvoice_detail .result_btn_box {
  margin-top: 15px; }

body.plum_content {
  background-color: #ecedf0; }
  body.plum_content .dropdown-menu {
    min-width: auto; }
  body.plum_content .table-search {
    margin-bottom: 0; }
    body.plum_content .table-search th {
      background-color: #fff0f6;
      width: 160px; }
  body.plum_content .table-apply-data {
    margin-bottom: 0; }
    body.plum_content .table-apply-data th {
      background-color: #fff0f6;
      width: 120px; }
  body.plum_content .table-apply-data2 {
    margin-bottom: 0; }
    body.plum_content .table-apply-data2 th {
      background-color: #fff0f6; }
  body.plum_content .pagination_box {
    text-align: center; }
  body.plum_content .chat_area h4 {
    font-weight: bold;
    border-left: 3px solid #298470;
    font-size: 14px;
    padding: 2px;
    padding-left: 6px;
    margin: 0; }
  body.plum_content .btn-vsm {
    font-size: 11px;
    padding: 3px;
    line-height: 1;
    margin-top: 6px; }
  body.plum_content .beri_form_box {
    padding: 15px;
    border: 2px solid #ccc;
    background-color: #f6f6f6;
    position: relative; }
    body.plum_content .beri_form_box dt {
      font-size: 12px;
      margin-bottom: 5px; }
    body.plum_content .beri_form_box .error_message {
      display: none; }
      body.plum_content .beri_form_box .error_message p {
        font-size: 11px;
        color: red;
        margin-bottom: 0;
        margin-top: 5px; }
  body.plum_content .border-box {
    border: 1px solid #ccc;
    padding: 10px; }
    body.plum_content .border-box .bold {
      font-weight: bold; }
  body.plum_content .ws-pl {
    white-space: pre-line; }
  body.plum_content .no_break_in_span span {
    display: inline-block;
    white-space: nowrap; }
  body.plum_content .colors {
    padding: 0;
    list-style: none;
    text-align: left; }
    body.plum_content .colors:after {
      content: "";
      display: table;
      clear: both; }
    body.plum_content .colors li {
      float: left;
      margin-right: 1em; }
      body.plum_content .colors li > :first-child {
        margin-right: .25em; }
        body.plum_content .colors li > :first-child::before {
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.125); }
        body.plum_content .colors li > :first-child:empty:not(.fa)::before {
          font-family: FontAwesome;
          content: "\f0c8"; }
    body.plum_content .colors.circle li > :first-child:empty:not(.fa)::before {
      content: "\f111";
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.25); }
    body.plum_content .colors.border li > :first-child:empty:not(.fa)::before {
      margin: 0 2px 0 1px;
      text-shadow: 1px  1px #ddd, 1px -1px #ddd, -1px  1px #ddd, -1px -1px #ddd; }
  body.plum_content .link_cell {
    position: relative; }
    body.plum_content .link_cell .link_btn:hover {
      text-decoration: underline; }
      body.plum_content .link_cell .link_btn:hover .fa::before {
        border-bottom: solid 1px; }
    body.plum_content .link_cell .link_btn::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      margin: auto; }
  body.plum_content .new_info {
    background-color: #FEFF00; }
    body.plum_content .new_info:hover {
      background-color: #e5e600; }
  body.plum_content .info_list_content {
    white-space: pre-line; }
  body.plum_content [name="post_info"] .error_message,
  body.plum_content [name="post_faq"] .error_message {
    color: red;
    font-size: 12px;
    margin-bottom: 0;
    display: none; }
  body.plum_content .manual_form_box {
    padding: 15px;
    border: 2px solid #ccc;
    background-color: #f6f6f6; }
  body.plum_content [name="post_manual"] .form-inline label {
    float: left; }
  body.plum_content [name="post_manual"] .form-inline #manual_btn {
    border-radius: 0; }
    body.plum_content [name="post_manual"] .form-inline #manual_btn + p {
      overflow: hidden; }
      body.plum_content [name="post_manual"] .form-inline #manual_btn + p .placeholder {
        color: #bdbdbd; }
  body.plum_content [name="post_manual"] .error_message {
    color: red;
    font-size: 12px; }
  body.plum_content .faq_table .question,
  body.plum_content .faq_table .answer {
    padding-left: 1.25em; }
    body.plum_content .faq_table .question::before,
    body.plum_content .faq_table .answer::before {
      display: inline-block;
      width: 1em;
      margin-right: .25em;
      margin-left: -1.25em; }
  body.plum_content .faq_table .question::before {
    content: "Q.";
    color: #ff0b71; }
  body.plum_content .faq_table .answer {
    white-space: pre-line;
    margin-bottom: 0; }
    body.plum_content .faq_table .answer::before {
      content: "A.";
      color: #be3b3b; }
  body.plum_content .faq_table tr.ui-sortable-helper {
    display: table;
    cursor: move;
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing; }
    body.plum_content .faq_table tr.ui-sortable-helper td:nth-of-type(2) {
      display: table-cell; }
  body.plum_content .faq_table td.ui-sortable-handle {
    cursor: pointer;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; }
    body.plum_content .faq_table td.ui-sortable-handle:active {
      cursor: move;
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing; }
  body.plum_content .faq_repl_expl {
    font-size: 12px;
    color: #006f90;
    border: solid 1px #006f90;
    padding: 0 .5em; }
  body.plum_content .beri_type_select {
    width: 90%;
    margin: 0 auto; }
    body.plum_content .beri_type_select > label:not(:last-of-type) {
      border-right: none; }
    body.plum_content .beri_type_select :checked + label {
      color: #FFF;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      background-color: #298470;
      border-color: #298470 #298470 #1d5d4f; }
  body.plum_content .result_select_group .btn {
    white-space: nowrap; }
  body.plum_content .result_select_group .dropdown-menu {
    transform-origin: top;
    animation: dropdown-menu-expand .125s; }
    body.plum_content .result_select_group .dropdown-menu li {
      cursor: pointer; }
  body.plum_content .result_btn_group .btn-group {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    /*			@include max-screen(1280px) {
				@include flex-flow(column);

				.btn {
					@include flex(1);

					&:nth-child(2):last-child {
						margin: 15px 0 0;
					}
				}
			}*/ }
    body.plum_content .result_btn_group .btn-group .btn {
      white-space: nowrap;
      padding: 5px 2px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      body.plum_content .result_btn_group .btn-group .btn.pass_btn {
        background-color: #6198C9;
        border-color: #6198C9 #6198C9 #4177A8; }
        body.plum_content .result_btn_group .btn-group .btn.pass_btn:hover {
          background-color: #70a2ce;
          border-color: #70a2ce; }
        body.plum_content .result_btn_group .btn-group .btn.pass_btn:active {
          background-color: #6198C9;
          border-color: #6198C9; }
      body.plum_content .result_btn_group .btn-group .btn.approve_btn {
        background-color: #61C99A;
        border-color: #61C99A #61C99A #45AF7F; }
        body.plum_content .result_btn_group .btn-group .btn.approve_btn:hover {
          background-color: #7cd2ab;
          border-color: #7cd2ab; }
        body.plum_content .result_btn_group .btn-group .btn.approve_btn:active {
          background-color: #59c695;
          border-color: #59c695; }
      body.plum_content .result_btn_group .btn-group .btn.reject_btn {
        background-color: #DC8273;
        border-color: #DC8273 #DC8273 #C26657; }
        body.plum_content .result_btn_group .btn-group .btn.reject_btn:hover {
          background-color: #e09183;
          border-color: #e09183; }
        body.plum_content .result_btn_group .btn-group .btn.reject_btn:active {
          background-color: #da7b6b;
          border-color: #da7b6b; }
      body.plum_content .result_btn_group .btn-group .btn:nth-child(2):last-child {
        margin-left: 12px; }
  body.plum_content .set_result_expl {
    font-size: 20px;
    font-weight: bold; }
    body.plum_content .set_result_expl[data-result="1"] {
      color: #2d2dd2; }
    body.plum_content .set_result_expl[data-result="2"] {
      color: #d2322d; }
    body.plum_content .set_result_expl[data-result="4"] {
      color: #1d872b; }
    body.plum_content .set_result_expl::before {
      content: "『"; }
    body.plum_content .set_result_expl::after {
      content: "』"; }
  body.plum_content .calendars {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    counter-reset: month; }
    body.plum_content .calendars .calendar {
      counter-increment: month;
      margin-bottom: 20px; }
      body.plum_content .calendars .calendar::before {
        content: counter(month) "月";
        display: block;
        font-size: 15px;
        font-weight: bold;
        padding-bottom: 5px; }
      body.plum_content .calendars .calendar th, body.plum_content .calendars .calendar td {
        text-align: center; }
        body.plum_content .calendars .calendar th:first-child, body.plum_content .calendars .calendar td:first-child {
          color: #d2322d; }
        body.plum_content .calendars .calendar th:last-child, body.plum_content .calendars .calendar td:last-child {
          color: #2d2dd2; }
      body.plum_content .calendars .calendar th:empty:nth-child(1)::before {
        content: "日"; }
      body.plum_content .calendars .calendar th:empty:nth-child(2)::before {
        content: "月"; }
      body.plum_content .calendars .calendar th:empty:nth-child(3)::before {
        content: "火"; }
      body.plum_content .calendars .calendar th:empty:nth-child(4)::before {
        content: "水"; }
      body.plum_content .calendars .calendar th:empty:nth-child(5)::before {
        content: "木"; }
      body.plum_content .calendars .calendar th:empty:nth-child(6)::before {
        content: "金"; }
      body.plum_content .calendars .calendar th:empty:nth-child(7)::before {
        content: "土"; }
      body.plum_content .calendars .calendar td:empty {
        background-color: whitesmoke;
        cursor: not-allowed; }
      body.plum_content .calendars .calendar td:not(:empty) {
        padding: 0; }
        body.plum_content .calendars .calendar td:not(:empty) input {
          display: none; }
          body.plum_content .calendars .calendar td:not(:empty) input:checked + label {
            background-color: #ff8abb;
            text-shadow: 0 0 1px white; }
        body.plum_content .calendars .calendar td:not(:empty) label {
          cursor: pointer;
          margin: 0;
          display: block;
          padding: 8px;
          transition: background-color .125s; }
          body.plum_content .calendars .calendar td:not(:empty) label[data-day]::before {
            content: attr(data-day); }
          body.plum_content .calendars .calendar td:not(:empty) label:hover {
            background-color: #fff0f6; }
          body.plum_content .calendars .calendar td:not(:empty) label.preselected {
            background-color: #ff3e8e;
            text-shadow: 0 0 1px white; }
    body.plum_content .calendars.selred .calendar td:not(:empty) input:checked + label {
      background-color: #f2c2c0; }
    body.plum_content .calendars.selred .calendar td:not(:empty) label:hover {
      background-color: #fbebea; }
    body.plum_content .calendars.selred .calendar td:not(:empty) label.preselected {
      background-color: #edadab;
      text-shadow: 0 0 1px white; }
  body.plum_content .logs {
    font-size: 12px; }
    body.plum_content .logs th {
      font-weight: normal;
      padding-right: 1em; }
  body.plum_content .modal .btn-primary:active,
  body.plum_content .modal .btn-primary.active,
  body.plum_content .modal .open > .dropdown-toggle.btn-primary
.btn-primary:active:hover,
  body.plum_content .modal .btn-primary.active:hover,
  body.plum_content .modal .open > .dropdown-toggle.btn-primary:hover,
  body.plum_content .modal .btn-primary:active:focus,
  body.plum_content .modal .btn-primary.active:focus,
  body.plum_content .modal .open > .dropdown-toggle.btn-primary:focus,
  body.plum_content .modal .btn-primary:active.focus,
  body.plum_content .modal .btn-primary.active.focus,
  body.plum_content .modal .open > .dropdown-toggle.btn-primary.focus {
    background-color: #23715f;
    border-color: #23715f; }
  body.plum_content .chat_message .chat_btn_group {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex; }
    body.plum_content .chat_message .chat_btn_group .btn {
      margin-left: 2px; }
      body.plum_content .chat_message .chat_btn_group .btn.chat_save_btn {
        display: none; }
        body.plum_content .chat_message .chat_btn_group .btn.chat_save_btn:disabled::after {
          content: '中'; }
  body.plum_content .chat_message .chat_comment {
    transition: background-color .125s; }
    body.plum_content .chat_message .chat_comment[contenteditable="true"] {
      background-color: white;
      outline: dashed 1px #bd004f;
      outline-offset: 1px; }
  body.plum_content .batch_btns {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    body.plum_content .batch_btns ul.pagination {
      margin: 0; }
  body.plum_content .middle_center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  body.plum_content .selnone {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  body.plum_content .header_btns {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: inherit; }
  body.plum_content [data-nav="red_invoice"] {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column;
    -moz-flex-flow: column;
    flex-flow: column; }
    body.plum_content [data-nav="red_invoice"] .red_invoice_item {
      -webkit-box-ordinal-group: -1;
      -moz-box-ordinal-group: -1;
      box-ordinal-group: -1;
      -webkit-order: -1;
      -moz-order: -1;
      order: -1;
      -ms-flex-order: -1; }

@keyframes dropdown-menu-expand {
  from {
    transform: scaleY(0); }
  to {
    transform: none; } }

.plum_content.modal-open .datepicker {
  z-index: 1060 !important; }

#tel_change_modal .form-dl, #tel_waiting_modal .form-dl {
  margin-bottom: 7px;
  display: flex;
  align-items: center; }
  #tel_change_modal .form-dl dt, #tel_waiting_modal .form-dl dt {
    width: 100px;
    padding-right: 10px;
    text-align: right; }
  #tel_change_modal .form-dl dd, #tel_waiting_modal .form-dl dd {
    flex: 1; }

#tel_change_modal .error_message, #tel_waiting_modal .error_message {
  background-color: #FFF; }
  #tel_change_modal .error_message p, #tel_waiting_modal .error_message p {
    margin-bottom: 0;
    color: red;
    font-size: 11px;
    margin-top: 10px; }

#result_btn_list,
#result_btn_list li {
  list-style: none;
  padding: 0;
  margin: 0; }

#result_btn_list li {
  display: inline-block; }

#result_btn_list #kaketsu,
#result_btn_list #horyu {
  padding-right: 10px; }

#result_btn_list span {
  display: inline-block;
  padding: 7px 14px;
  background-color: #f0f0f0;
  border: 1px solid #e5e5e5;
  color: #777;
  border-radius: 4px;
  cursor: pointer; }

#result_btn_list #hiketsu {
  float: right;
  font-size: 11px; }
  #result_btn_list #hiketsu span {
    padding: 5px 10px;
    margin-top: 3px; }

#result_btn_list #kaketsu.cur span {
  background: #6198C9;
  border: 1px solid #4177A8;
  color: #FFF; }

#result_btn_list #horyu.cur span {
  background: #DCDC73;
  border: 1px solid #C4C45A;
  color: #FFF; }

#result_btn_list #saishu.cur span {
  background: #61C99A;
  border: 1px solid #45AF7F;
  color: #FFF; }

#result_btn_list #hiketsu.cur span {
  background: #DC8273;
  border: 1px solid #C26657;
  color: #FFF; }

#fubi_flag_contractimg,
#fubi_flag_contract,
#fubi_flag_ident,
#must_edit_member,
#must_edit_customer {
  display: inline-block;
  padding: 5px;
  width: 80px;
  background-color: #F2F2F2;
  border: 1px solid #C5C5C5;
  text-align: center;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer; }
  #fubi_flag_contractimg.cur,
  #fubi_flag_contract.cur,
  #fubi_flag_ident.cur,
  #must_edit_member.cur,
  #must_edit_customer.cur {
    padding: 4px;
    background-color: #3C87F6;
    border: 2px solid #2167CD;
    color: #FFF; }

/* テスト環境 */
body.plum_content.staging .center-sign .logo {
  color: #575757; }

body.plum_content.staging .center-sign .panel-sign .panel-body {
  border-color: #575757; }

body.plum_content.staging .center-sign .panel-sign .title {
  background-color: #575757; }

body.plum_content.staging .center-sign form [type="submit"] {
  background-color: #575757;
  border-color: #575757 #575757 #3D3D3D; }

body.plum_content.staging .header {
  background-color: #3C3C3C;
  border-bottom-color: #6D6D6D;
  border-top-color: #000; }
  body.plum_content.staging .header .logo {
    color: #FFF; }
  body.plum_content.staging .header .separator {
    background-color: #555;
    background-image: -webkit-linear-gradient(#555 60%, #5E5E5E);
    background-image: linear-gradient(#555 60%, #5E5E5E); }
  body.plum_content.staging .header .userbox .name {
    color: #CCC; }
  body.plum_content.staging .header .userbox.open .name {
    color: #001; }

body.plum_content.staging .page-header h2 {
  border-color: #575757; }

body.plum_content.staging .nano > .nano-pane > .nano-slider {
  background-color: #575757; }

body.plum_content.staging ul.nav-main > li.nav-active > a {
  box-shadow: 2px 0 0 #575757 inset; }

body.plum_content.staging .client_content h3 {
  color: #575757;
  border-color: #575757;
  background-color: #F0F0F0; }
  body.plum_content.staging .client_content h3 span {
    border-color: #575757; }

body.plum_content.staging .client_content h4 {
  border-color: #575757; }

body.plum_content.staging .client_content th, body.plum_content.staging .client_content td {
  border-color: #BDBDBD; }

body.plum_content.staging .client_content th {
  color: #575757; }

body.plum_content.staging .client_content .form_table tr:nth-child(even) {
  background-color: #F0F0F0; }

body.plum_content.staging .table-search th {
  background-color: #F0F0F0; }

body.plum_content.staging .table-apply-data th {
  background-color: #F0F0F0; }

body.plum_content.staging .table-apply-data2 th {
  background-color: #F0F0F0; }

body.plum_content.staging .panel-primary > .panel-heading {
  background-color: #575757;
  border-bottom-color: #757575; }

body.plum_content.staging .form_area.confirm {
  border-color: #88a6af; }

body.plum_content.staging .form_area.user-confirm {
  border-color: #a18e72; }

/*=====  End of Include Custom SCSS  ======*/
