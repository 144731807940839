.portlet-handler {
	cursor: move;
}

.portlet-placeholder {
	margin-bottom: 15px;
	padding: 0;
	border: 1px dashed #dddddd;
	background: #fafafa;
	color: #444444;
}