.chat_area{

	margin-left: -15px;
	margin-right: -15px;

	&.plum_area{
		margin-left: 0;
		margin-right: 0;

		.chat_box{
			overflow-y: auto;
			//overflow-x: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
		}
	}

	.menu_list{
		height: 100%;
		border-right: 1px solid #ccc;

		

		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		ul{
			padding: 0;


		}
		
		li{
			cursor: pointer;
			list-style: none;
			padding-left: 0;

			margin-right: -15px;
			margin-left: -15px;
			padding: 10px;
			border-bottom: 1px solid #CCC;
		}
		.number{

		}

		.id{
			font-size: 11px;
			font-weight: bold;
		}
		.name{
			font-size: 20px;
			font-weight: bold;
			display: block;
			margin-top: 5px;
		}
		.kana{
			font-size: 12px;
			font-weight: bold;
			display: block;
			color:#666;
		}


		.notice{
			background-color: #FFDEDE;
		}

		.cur{
			background-color: #FAFFDE;
		}

	}

	.chat_box{

		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		.detail{
			margin: 10;
			border:2px solid $main-color;

			padding: 20px;

			.table1{
				.two{
					th{
						width:17%;
					}
					td{
						width:33%;
					}
				}
			}
		}

		.input_form{
			padding: 10px;
			border: 1px solid #CCC;
		}

		.message_list{

			.message_item{
				margin-bottom: 10px;
				border: 1px solid #666;
				padding: 10px;

				border-radius: 4px;

				&.plum{
					background-color: $big-thin-color;
				}

				.message_line{
					margin-top: 0;
					border-bottom: 1px solid #ccc;
				}
			}
		}
	}

	

}